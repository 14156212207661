// Interfaces
import type { NuxtAxiosInstance } from '@nuxtjs/axios';
import type { PipelineType } from '@@/src/shared/api/Meeting/types';
import { useProjectsStore } from '@@/src/shared/@pinia/use-projects-store';
import type { StateTree } from 'pinia';
import { RestClient } from './Rest.client';
import { useMeetingStore } from '../../@pinia';

export class StatefulRestClient extends RestClient {
  piniaStore: Record<string, StateTree>;

  constructor($apiInstance: NuxtAxiosInstance, $pinia: Record<string, StateTree>) {
    super($apiInstance);

    this.piniaStore = $pinia;
  }

  protected getProjectSlug(): string {
    return String(useProjectsStore().activeProjectSlug || '');
  }

  protected getPipelineType(): PipelineType {
    return useMeetingStore().pipeline;
  }

  protected getMeetingId() {
    return useMeetingStore().meeting.id || '';
  }
}
