var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "mortgage-results"
  }, [_c(_setup.MortgageResultsHeader, {
    on: {
      "click:print": _setup.onPrintButtonClick
    }
  }), _vm._v(" "), _c(_setup.MortgageResultsTable, {
    attrs: {
      "mortgage-state": _setup.mortgageState,
      "loan-amount": _setup.loanAmount,
      "program-type": _setup.programType,
      "installment-state": _setup.savedInstallmentState
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };