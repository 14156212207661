/**
 * Инициализация активной встречи
 */
import { defineNuxtMiddleware } from '@nuxtjs/composition-api';
import {
  getUserNamespacedCookie,
} from '@@/src/shared/lib/helpers/store/cookie';
import {
  isMeetingAvailableOnRoute,
} from '@@/src/shared/lib/helpers/route/routeOption';
import { usePortraitsService } from '@@/src/shared/lib/services/portraits';
import { useMeetingStore, useUserRightsStore } from '../shared/@pinia';

export default defineNuxtMiddleware(async ({ $cookies, route, redirect, $auth, $pinia }) => {
  if (
    !$auth.loggedIn
    || !isMeetingAvailableOnRoute(route)
  ) {
    return;
  }
  const portraitsService = usePortraitsService();
  const meetingStore = useMeetingStore($pinia);

  await portraitsService.getCurrentPortrait();
  await portraitsService.getProjectPortraits();

  const routeName = route.name;
  if (routeName?.includes('project-slug-meeting-index-') && useUserRightsStore().isNoMeetingsAccess) {
    return redirect({ name: 'project-slug-meeting-index', params: route.params });
  }

  await meetingStore.getActiveMeeting(true);

  // Если удалось получить текущую встречу, то восстановить состояние начала презентации,
  // иначе - сбросить
  const isDemoStarted = getUserNamespacedCookie(
    $cookies,
    'isDemoStarted',
    $auth.user?.id,
  );

  meetingStore.setDemo(isDemoStarted);
});
