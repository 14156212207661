import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.number.constructor.js";
var render = function render() {
  var _setup$installmentSta;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "installment__filter-content"
  }, [_c('SInputNext', {
    attrs: {
      "model-value": _setup.installmentState.realEstatePrice,
      "theme": "panel-white",
      "label": "Стоимость недвижимости, ₽",
      "split-thousands": "",
      "min": 0,
      "precision": 0,
      "required": "",
      "error-show": _setup.checkError(_setup.installmentState.realEstatePrice),
      "error-message": "Обязательное поле"
    },
    on: {
      "update:rawValue": function updateRawValue($event) {
        _setup.installmentState.realEstatePrice = $event;
      }
    }
  }), _vm._v(" "), _c('SInputRangePercentNext', {
    key: "initialPaymentPercent-".concat(_setup.isResetFilters),
    attrs: {
      "theme": "panel-white",
      "label": "Первоначальный взнос, ₽",
      "split-thousands": "",
      "precision": 0,
      "min": 0,
      "max": 99,
      "step": 1,
      "required": "",
      "error-show": _setup.checkError(_setup.installmentState.initialPayment),
      "error-message": "Обязательное поле",
      "percent": _setup.installmentState.initialPaymentPercent || 0,
      "total": Number(_setup.installmentState.calculatedEstatePrice)
    },
    on: {
      "update:currency": function updateCurrency($event) {
        _setup.installmentState.initialPayment = $event;
      },
      "update:percent": function updatePercent($event) {
        _setup.installmentState.initialPaymentPercent = $event;
      }
    }
  }), _vm._v(" "), _c('SInputRangePercentNext', {
    key: "discountPercent-".concat(_setup.isResetFilters),
    attrs: {
      "theme": "panel-white",
      "label": "Скидка, ₽",
      "split-thousands": "",
      "precision": 0,
      "min": 0,
      "max": 99,
      "percent": _setup.installmentState.discountPercent,
      "step": 1,
      "total": Number(_setup.installmentState.realEstatePrice)
    },
    on: {
      "update:currency": function updateCurrency($event) {
        _setup.installmentState.discount = $event;
      },
      "update:percent": function updatePercent($event) {
        _setup.installmentState.discountPercent = $event;
      }
    }
  }), _vm._v(" "), _c('SInputNext', {
    attrs: {
      "theme": "panel-white",
      "label": "Стоимость брони, ₽",
      "precision": 0,
      "min": 0,
      "split-thousands": ""
    },
    on: {
      "update:rawValue": function updateRawValue($event) {
        _setup.installmentState.bookingPrice = $event;
      }
    },
    model: {
      value: _setup.installmentState.bookingPrice,
      callback: function callback($$v) {
        _vm.$set(_setup.installmentState, "bookingPrice", $$v);
      },
      expression: "installmentState.bookingPrice"
    }
  }), _vm._v(" "), _c('div', [_c('button', {
    staticClass: "category-item",
    class: {
      active: _setup.isRepairOption
    },
    on: {
      "click": function click($event) {
        _setup.isRepairOption = !_setup.isRepairOption;
      }
    }
  }, [_vm._v("\n      Ремонт\n      "), _c('SIcon', {
    staticClass: "chevron-icon",
    attrs: {
      "icon": "chevron-down",
      "size": "24"
    }
  })], 1), _vm._v(" "), _c('SExpander', {
    attrs: {
      "is-open": _setup.isRepairOption
    }
  }, [_c('div', {
    staticClass: "installment__filter-content"
  }, [_c('SCheckbox', {
    staticClass: "expander-indent",
    attrs: {
      "label-color": "white",
      "theme": "secondary"
    },
    model: {
      value: _setup.installmentState.isJustRestRoom,
      callback: function callback($$v) {
        _vm.$set(_setup.installmentState, "isJustRestRoom", $$v);
      },
      expression: "installmentState.isJustRestRoom"
    }
  }, [_vm._v("\n          Только ремонт санузла\n        ")]), _vm._v(" "), _c('SInputNext', {
    attrs: {
      "theme": "panel-white",
      "label": "Площадь квартиры, м²",
      "min": 0,
      "disabled": _setup.installmentState.isJustRestRoom,
      "split-thousands": ""
    },
    on: {
      "update:rawValue": function updateRawValue($event) {
        _setup.installmentState.square = $event;
      }
    },
    model: {
      value: _setup.installmentState.square,
      callback: function callback($$v) {
        _vm.$set(_setup.installmentState, "square", $$v);
      },
      expression: "installmentState.square"
    }
  }), _vm._v(" "), _setup.installmentState.isJustRestRoom ? _c('SInputNext', {
    attrs: {
      "theme": "panel-white",
      "label": "Стоимость ремонта, ₽",
      "precision": 0,
      "min": 0,
      "split-thousands": ""
    },
    on: {
      "update:rawValue": function updateRawValue($event) {
        _setup.installmentState.repairCostRub = $event;
      }
    },
    model: {
      value: _setup.installmentState.repairCostRub,
      callback: function callback($$v) {
        _vm.$set(_setup.installmentState, "repairCostRub", $$v);
      },
      expression: "installmentState.repairCostRub"
    }
  }) : _c('SInputNext', {
    attrs: {
      "theme": "panel-white",
      "label": "Стоимость ремонта, м²",
      "precision": 0,
      "min": 0,
      "split-thousands": ""
    },
    on: {
      "update:rawValue": function updateRawValue($event) {
        _setup.installmentState.repairCost = $event;
      }
    },
    model: {
      value: _setup.installmentState.repairCost,
      callback: function callback($$v) {
        _vm.$set(_setup.installmentState, "repairCost", $$v);
      },
      expression: "installmentState.repairCost"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "expander-indent"
  }, [_c('button', {
    staticClass: "category-item",
    class: {
      active: _setup.isOptions
    },
    on: {
      "click": function click($event) {
        _setup.isOptions = !_setup.isOptions;
      }
    }
  }, [_vm._v("\n          Доп. опции\n          "), _c('SIcon', {
    staticClass: "chevron-icon",
    attrs: {
      "icon": "chevron-down",
      "size": "24"
    }
  })], 1), _vm._v(" "), _c('SExpander', {
    attrs: {
      "is-open": _setup.isOptions
    }
  }, [_c('div', {
    staticClass: "installment__filter-content"
  }, [_c('SInputNext', {
    staticClass: "expander-indent",
    attrs: {
      "theme": "panel-white",
      "label": "Кухня с техникой, ₽",
      "precision": 0,
      "min": 0,
      "split-thousands": ""
    },
    on: {
      "update:rawValue": function updateRawValue($event) {
        _setup.installmentState.kitchen = $event;
      }
    },
    model: {
      value: _setup.installmentState.kitchen,
      callback: function callback($$v) {
        _vm.$set(_setup.installmentState, "kitchen", $$v);
      },
      expression: "installmentState.kitchen"
    }
  }), _vm._v(" "), _c('SInputNext', {
    attrs: {
      "theme": "panel-white",
      "label": "Меблировка, ₽",
      "precision": 0,
      "min": 0,
      "split-thousands": ""
    },
    on: {
      "update:rawValue": function updateRawValue($event) {
        _setup.installmentState.furniture = $event;
      }
    },
    model: {
      value: _setup.installmentState.furniture,
      callback: function callback($$v) {
        _vm.$set(_setup.installmentState, "furniture", $$v);
      },
      expression: "installmentState.furniture"
    }
  }), _vm._v(" "), _c('SInputNext', {
    attrs: {
      "theme": "panel-white",
      "label": "Умный дом, ₽",
      "precision": 0,
      "min": 0,
      "split-thousands": ""
    },
    on: {
      "update:rawValue": function updateRawValue($event) {
        _setup.installmentState.smartHome = $event;
      }
    },
    model: {
      value: _setup.installmentState.smartHome,
      callback: function callback($$v) {
        _vm.$set(_setup.installmentState, "smartHome", $$v);
      },
      expression: "installmentState.smartHome"
    }
  }), _vm._v(" "), _c('SInputNext', {
    attrs: {
      "theme": "panel-white",
      "label": "Другое, ₽",
      "precision": 0,
      "min": 0,
      "split-thousands": ""
    },
    on: {
      "update:rawValue": function updateRawValue($event) {
        _setup.installmentState.other = $event;
      }
    },
    model: {
      value: _setup.installmentState.other,
      callback: function callback($$v) {
        _vm.$set(_setup.installmentState, "other", $$v);
      },
      expression: "installmentState.other"
    }
  })], 1)])], 1)])], 1), _vm._v(" "), _setup.SETTLEMENT_TYPES.length ? _c('SSelect', {
    attrs: {
      "z-index": "1000",
      "placeholder": "Выберете тип расчёта",
      "label": "Тип расчёта",
      "theme": "panel-white"
    },
    model: {
      value: _setup.installmentState.selectedSettlementType,
      callback: function callback($$v) {
        _vm.$set(_setup.installmentState, "selectedSettlementType", $$v);
      },
      expression: "installmentState.selectedSettlementType"
    }
  }, _vm._l(_setup.SETTLEMENT_TYPES, function (option, index) {
    return _c('SOption', {
      key: "option-".concat(option.label, "-").concat(index, "}"),
      attrs: {
        "value": option.value,
        "label": option.label
      }
    }, [_vm._v("\n      " + _vm._s(option.label) + "\n    ")]);
  }), 1) : _vm._e(), _vm._v(" "), _c('SInputDate', {
    key: "installmentPeriod-".concat(_setup.isResetFilters),
    attrs: {
      "input-theme": "panel-white",
      "use-teleport": "",
      "label": "Срок, лет",
      "interval": "",
      "required": "",
      "disabled-functor": _setup.isDateDisabled,
      "error-show": !_setup.installmentState.installmentPeriod,
      "error-message": "Обязательное поле"
    },
    model: {
      value: _setup.installmentState.installmentPeriod,
      callback: function callback($$v) {
        _vm.$set(_setup.installmentState, "installmentPeriod", $$v);
      },
      expression: "installmentState.installmentPeriod"
    }
  }), _vm._v(" "), _c('SInputNext', {
    staticClass: "payment-indent",
    attrs: {
      "theme": "panel-white",
      "label": "Размер платежа, ₽",
      "split-thousands": "",
      "required": "",
      "min": 0,
      "error-show": _setup.checkError(_setup.installmentState.payment),
      "error-message": "Обязательное поле"
    },
    on: {
      "update:rawValue": function updateRawValue($event) {
        _setup.installmentState.payment = $event;
      }
    },
    model: {
      value: _setup.installmentState.payment,
      callback: function callback($$v) {
        _vm.$set(_setup.installmentState, "payment", $$v);
      },
      expression: "installmentState.payment"
    }
  }), _vm._v(" "), (_setup$installmentSta = _setup.installmentState.additionalPayments) !== null && _setup$installmentSta !== void 0 && _setup$installmentSta.length ? _c('div', {
    staticClass: "payments"
  }, [_c('div', {
    staticClass: "payments__title"
  }, [_vm._v("Дополнительные платежи")]), _vm._v(" "), _c('div', {
    staticClass: "payments__list"
  }, [_vm._l(_setup.installmentState.additionalPayments, function (payment) {
    return [_c('span', {
      key: "date-".concat(payment.id)
    }, [_vm._v(_vm._s(payment.dateString))]), _vm._v(" "), _c('span', {
      key: "amount-".concat(payment.id)
    }, [_vm._v(_vm._s(payment.amount) + " ₽")]), _vm._v(" "), _c('div', {
      key: "actions-".concat(payment.id),
      staticClass: "payments__actions"
    }, [_c('SButton', {
      attrs: {
        "equal": "",
        "theme": "black-transparent"
      },
      on: {
        "click": function click($event) {
          _setup.isShowModal = true;
        }
      }
    }, [_c('SIcon', {
      staticClass: "payments__icon",
      attrs: {
        "icon": "pencil",
        "size": "20"
      }
    })], 1), _vm._v(" "), _c('SButton', {
      attrs: {
        "equal": "",
        "theme": "black-transparent"
      },
      on: {
        "click": function click($event) {
          return _setup.deletePayment(payment.id);
        }
      }
    }, [_c('SIcon', {
      staticClass: "payments__icon",
      attrs: {
        "icon": "close",
        "size": "20",
        "equal": ""
      }
    })], 1)], 1)];
  })], 2)]) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };