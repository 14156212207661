import type { NuxtAxiosInstance } from '@nuxtjs/axios';
import { StatefulRestClient } from '@@/src/shared/api/Base/StatefulRest.client';
import type { FavoritesResponse } from '@@/src/shared/api/Favorites/types/Favorites.types';
import { usePanelApi, usePiniaStore, usePortalApi } from '@@/src/app/plugins';
import type { RoutesDictionary } from '@@/src/app/plugins/api/routes';
import { useRoutes } from '@@/src/app/plugins/api/routes';
import type { FlatType, Maybe } from '@@/src/app/graphql/generated-types';
import type { RemovableRef } from '@vueuse/core';
import { useStorage } from '@vueuse/core';
import type { FlatsPageLot } from '@@/src/components/pages/flats/flats-list/composables/use-lot-values';
import { StatusType } from '@strana-artw/realty';
import { LotClient } from '../Lot/Lot.client';
import type { OfferCollection } from '../Offer/types/OfferCollection';

interface PayloadType {
  property: number;
  layout: number;
  rooms: Maybe<number> | undefined;
  area: number;
  project: string | undefined;
  floor_number: number | undefined;
  building: string | undefined;
  flat_number: string;
  price: number;
}

class FavoritesClient extends StatefulRestClient {
  readonly #portalApi: NuxtAxiosInstance;
  readonly #panelApi: NuxtAxiosInstance;
  readonly #lotClient: LotClient;
  readonly #routes: RoutesDictionary;

  favoritesStorage: RemovableRef<FlatType[]>;
  savedDiscounts: RemovableRef<{ [key: string]: string }>;

  FAVORITES_LIST_ENDPOINT = '/api/panel/meeting/#meeting#/get_favorites/';

  constructor() {
    const store = usePiniaStore();
    const panelApi = usePanelApi();
    const portalApi = usePortalApi();

    super(panelApi, store);
    this.#portalApi = portalApi;
    this.#panelApi = panelApi;
    this.#lotClient = new LotClient(this.#portalApi);
    this.#routes = useRoutes();

    this.favoritesStorage = useStorage('favorites', [], localStorage, { mergeDefaults: true });
    this.savedDiscounts = useStorage('discounts', {}, localStorage, { mergeDefaults: true });
  }

  async fetchIdList(): Promise<string[]> {
    const response = await this.sendGetRequest<FavoritesResponse>(
      this.FAVORITES_LIST_ENDPOINT,
      { meeting: this.getMeetingId() },
    );

    if (!response || !Array.isArray(response)) {
      return [];
    }

    return response.map(({ graphql_id }) => graphql_id);
  }

  async getList(meetingId?: string, lots?: FlatsPageLot[]): Promise<Array<unknown>> {
    let idList: string[] = [];
    const discountsWithIds: { [key: string]: number } = {};
    const combinedDiscountsWithIds: { [key: string]: string } = {};

    if (lots?.length) {
      lots.forEach((lot) => {
        const graphqlId = lot.graphqlId!;
        const discount = Number(lot.discount?.replace(/\s+/g, ''));

        discountsWithIds[graphqlId] = discount;
        combinedDiscountsWithIds[graphqlId] = lot.discountUntil ? `${discount} ${lot.discountUntil}` : `${discount}`;
      });
      this.savedDiscounts.value = combinedDiscountsWithIds;
    }

    if (meetingId) {
      idList = await this.fetchIdList();
    }
    else {
      const favorites = this.favoritesStorage?.value;
      idList = favorites?.map(({ id }) => id);
    }

    if (!idList?.length) {
      return [];
    }

    const lotsList = await this.#lotClient.getLotsList({
      id: idList,
      discounts: discountsWithIds,
      statuses: [StatusType.Free, StatusType.Soon],
    });

    if (lotsList?.results) {
      for (const discount of Object.keys(this.savedDiscounts.value)) {
        lotsList.results.forEach((lot: FlatsPageLot) => {
          if (lot.graphqlId === discount) {
            const [discountPrice, discountUntil] = this.savedDiscounts.value[discount].split(' ') || [];

            lot.discount = discountPrice;
            lot.discountUntil = discountUntil;
          }
        });
      }
    }

    return lotsList?.results || [];
  }

  async addToFavoritesList(meetingId: string,
    p: { favorite_property: number[] },
    payload: PayloadType,
    property: any) {
    if (meetingId) {
      await Promise.all([
        this.#panelApi.$post(this.#routes.favorites.base(meetingId), p),
        this.#panelApi.$post(this.#routes.favorites.amoBase(meetingId), payload),
      ]);
    }
    else {
      this.favoritesStorage?.value?.push(property);
    }
  }

  async deleteFromFavoritesList(meetingId: string,
    p: { favorite_property: number[] },
    lot: FlatType,
  ) {
    if (meetingId) {
      await Promise.all([
        this.#panelApi.$post(this.#routes.favorites.delete(meetingId), p),
        this.#panelApi.$post(this.#routes.favorites.deleteAmo(meetingId), p),
      ]);
    }
    else {
      const index = this.favoritesStorage?.value.findIndex(fav => fav.pk === lot.id);
      this.favoritesStorage?.value?.splice(index, 1);
    }

    this.savedDiscounts.value = Object.fromEntries(Object.entries(this.savedDiscounts.value).filter(([key]) => key !== lot.graphqlId));
  }

  async deleteAllFromFavoritesList(meetingId: string) {
    if (meetingId) {
      await this.#panelApi.$post(this.#routes.favorites.deleteAll(meetingId));
    }
    else {
      this.favoritesStorage.value = [];
    }

    this.savedDiscounts.value = {};
  }

  async sendOfferCollection(collection: OfferCollection) {
    await this.#panelApi.$post(this.#routes.favorites.offerCollection, collection);
  }
}

const useFavoritesClient = (): FavoritesClient => new FavoritesClient();

export { FavoritesClient, useFavoritesClient };
