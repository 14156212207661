import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url-search-params.js";
import { CURRENCY } from '@@/src/components/pages/mortgage';
import { splitThousands } from '@@/src/shared/lib/utils/commonUtils';
var periods = {
  month: 1,
  quarter: 3,
  year: 12
};
var monthNames = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];
var currentYear = 0;
var periodStartYear = 1;
export function getPeriodLabel(date, settlementType) {
  var isInstallment = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var year = date.getFullYear();
  var month = date.getMonth();
  if (settlementType === 'year') {
    if (!currentYear) {
      currentYear = year;
    } else if (currentYear !== year) {
      periodStartYear++;
      currentYear = year;
    }
    return isInstallment ? "".concat(periodStartYear, " \u0433\u043E\u0434 (").concat(year, ")") : "".concat(year);
  }
  if (settlementType === 'quarter') {
    var quarterIndex = Math.floor(month / 3);
    var quarters = ['I', 'II', 'III', 'IV'];
    return isInstallment ? "".concat(quarters[quarterIndex], " \u043A\u0432\u0430\u0440\u0442\u0430\u043B, ").concat(year) : "".concat(quarterIndex + 1, " \u043A\u0432\u0430\u0440\u0442\u0430\u043B, ").concat(year);
  }
  return "".concat(monthNames[month], ", ").concat(year);
}
export function getMortgageSummary(programs) {
  var maxCreditAmount = Math.max.apply(Math, _toConsumableArray(programs.map(function (p) {
    return p.creditAmount || 0;
  })));
  var minInitialPayment = Math.min.apply(Math, _toConsumableArray(programs.map(function (p) {
    return p.initialPayment || Number.POSITIVE_INFINITY;
  })));
  var maxCreditPeriod = Math.max.apply(Math, _toConsumableArray(programs.map(function (p) {
    return p.creditPeriod || 0;
  })));
  return {
    maxCreditAmount: maxCreditAmount,
    minInitialPayment: minInitialPayment,
    maxCreditPeriod: maxCreditPeriod
  };
}
export function getPrintPageUrl(mortgageState, loanAmount, monthlyPayment, savedInstallmentState, programType) {
  var queryParams;
  if (programType === 'mortgage') {
    var _isColumnsHidden$toSt;
    var realEstatePrice = mortgageState.realEstatePrice,
      initialPayment = mortgageState.initialPayment,
      initialPaymentPercent = mortgageState.initialPaymentPercent,
      loanPeriod = mortgageState.loanPeriod,
      interestRate = mortgageState.interestRate,
      earlyPayment = mortgageState.earlyPayment,
      earlyPaymentDate = mortgageState.earlyPaymentDate,
      earlyPaymentReduction = mortgageState.earlyPaymentReduction,
      earlyPaymentType = mortgageState.earlyPaymentType,
      isColumnsHidden = mortgageState.isColumnsHidden;
    var earlyPaymentQueryParams = {};
    if (earlyPayment && earlyPaymentDate && earlyPaymentReduction && earlyPaymentType) {
      earlyPaymentQueryParams = {
        earlyPayment: earlyPayment.toString(),
        earlyPaymentDate: earlyPaymentDate.toString(),
        earlyPaymentReduction: earlyPaymentReduction.toString(),
        earlyPaymentType: earlyPaymentType.toString()
      };
    }
    queryParams = new URLSearchParams(_objectSpread({
      realEstatePrice: realEstatePrice.toString(),
      initialPayment: initialPayment.toString(),
      initialPaymentPercent: initialPaymentPercent.toString(),
      loanPeriod: loanPeriod.toString(),
      interestRate: interestRate.toString(),
      loanAmount: loanAmount.toString(),
      monthlyPayment: monthlyPayment.toString(),
      isColumnsHidden: (_isColumnsHidden$toSt = isColumnsHidden === null || isColumnsHidden === void 0 ? void 0 : isColumnsHidden.toString()) !== null && _isColumnsHidden$toSt !== void 0 ? _isColumnsHidden$toSt : 'false'
    }, earlyPaymentQueryParams));
  } else {
    var _installmentPeriod$fr, _installmentPeriod$to;
    var installmentPeriod = savedInstallmentState.installmentPeriod,
      selectedSettlementType = savedInstallmentState.selectedSettlementType,
      _realEstatePrice = savedInstallmentState.realEstatePrice,
      discount = savedInstallmentState.discount,
      bookingPrice = savedInstallmentState.bookingPrice,
      kitchen = savedInstallmentState.kitchen,
      furniture = savedInstallmentState.furniture,
      _initialPayment = savedInstallmentState.initialPayment,
      smartHome = savedInstallmentState.smartHome,
      isJustRestRoom = savedInstallmentState.isJustRestRoom,
      other = savedInstallmentState.other,
      square = savedInstallmentState.square,
      discountPercent = savedInstallmentState.discountPercent,
      repairCostRub = savedInstallmentState.repairCostRub,
      _repairCost = savedInstallmentState.repairCost,
      payment = savedInstallmentState.payment,
      additionalPayments = savedInstallmentState.additionalPayments;
    queryParams = new URLSearchParams({
      from: (_installmentPeriod$fr = installmentPeriod === null || installmentPeriod === void 0 ? void 0 : installmentPeriod.from.toString()) !== null && _installmentPeriod$fr !== void 0 ? _installmentPeriod$fr : '',
      to: (_installmentPeriod$to = installmentPeriod === null || installmentPeriod === void 0 ? void 0 : installmentPeriod.to.toString()) !== null && _installmentPeriod$to !== void 0 ? _installmentPeriod$to : '',
      selectedSettlementType: selectedSettlementType.toString(),
      realEstatePrice: _realEstatePrice.toString(),
      discount: discount.toString(),
      bookingPrice: bookingPrice.toString(),
      kitchen: kitchen.toString(),
      furniture: furniture.toString(),
      initialPayment: _initialPayment.toString(),
      smartHome: smartHome.toString(),
      isJustRestRoom: isJustRestRoom.toString(),
      other: other.toString(),
      square: square.toString(),
      discountPercent: discountPercent.toString(),
      repairCostRub: repairCostRub.toString(),
      repairCost: _repairCost.toString(),
      payment: payment.toString(),
      additionalPayments: JSON.stringify(additionalPayments),
      programType: programType.toString()
    });
  }
  return "/print/mortgage?".concat(queryParams.toString());
}
export function createHeaderResultItems(mortgageState, monthlyPayment) {
  return [{
    label: 'Стоимость недвижимости',
    value: splitThousands(mortgageState.realEstatePrice),
    suffix: CURRENCY
  }, {
    label: 'Первоначальный взнос',
    value: "".concat(splitThousands(mortgageState.initialPaymentPercent), "% / ").concat(splitThousands(mortgageState.initialPayment)),
    suffix: CURRENCY
  }, {
    label: 'Срок кредита',
    value: splitThousands(mortgageState.loanPeriod),
    suffix: plural(mortgageState.loanPeriod, ['год', 'года', 'лет'])
  }, {
    label: 'Процентная ставка',
    value: splitThousands(mortgageState.interestRate),
    suffix: '%'
  }, {
    label: 'Ежемесячный платеж',
    value: splitThousands(monthlyPayment),
    suffix: CURRENCY
  }];
}
function priceWithDiscount(installmentState) {
  return Number(installmentState.realEstatePrice) - installmentState.discount;
}
function repairCost(installmentState) {
  if (installmentState.isJustRestRoom) {
    return Number(installmentState.repairCostRub);
  }
  return Number(installmentState.repairCost) * Number(installmentState.square);
}
function priceInDDU(installmentState) {
  return priceWithDiscount(installmentState) - Number(installmentState.bookingPrice) + repairCost(installmentState) + Number(installmentState.kitchen) + Number(installmentState.furniture) + Number(installmentState.smartHome) + Number(installmentState.other);
}
export function debtStartPeriod(installmentState) {
  return priceInDDU(installmentState) - Number(installmentState.initialPayment);
}
export function createHeaderResultInstallmentItems(installmentState) {
  return [{
    label: 'Размер скидки',
    value: "".concat(splitThousands(installmentState.discount)),
    suffix: CURRENCY
  }, {
    label: 'Стоимость со скидкой',
    value: "".concat(splitThousands(priceWithDiscount(installmentState))),
    suffix: CURRENCY
  }, {
    label: 'Стоимость ремонта',
    value: "".concat(splitThousands(repairCost(installmentState))),
    suffix: CURRENCY
  }, {
    label: 'Стоимость доп. опций',
    value: "".concat(splitThousands(Number(installmentState.kitchen) + Number(installmentState.furniture) + Number(installmentState.smartHome) + Number(installmentState.other))),
    suffix: CURRENCY
  }, {
    label: 'Размер ПВ',
    value: "".concat(splitThousands(Number(installmentState.initialPayment))),
    suffix: CURRENCY
  }, {
    label: 'Стоимость в ДДУ',
    value: "".concat(splitThousands(priceInDDU(installmentState))),
    suffix: CURRENCY
  }, {
    label: 'Остаток долга на начало периода',
    value: "".concat(splitThousands(debtStartPeriod(installmentState))),
    suffix: CURRENCY
  }];
}
/**
 * Рассчитывает ежемесячный платёж по ипотеке
 * @param loanAmount Сумма займа (основной долг)
 * @param interestRate Процентная ставка (годовая)
 * @param loanPeriod Срок займа в годах
 * @returns Массив объектов MortgageResultsTableItem
 */
export function calculateMonthlyPayment(loanAmount, interestRate, loanPeriod) {
  var monthsLeft = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
  var monthlyRate = interestRate / 100 / 12;
  var totalPayments = monthsLeft || loanPeriod * 12;
  var payment = loanAmount * (monthlyRate / (1 - Math.pow(1 + monthlyRate, -totalPayments)));
  return Number(payment.toFixed(0));
}
function isLeapYear(year) {
  return year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
}
export function calculateMonthlyInterest(remainingLoan, interestRate, month) {
  var daysInYear = isLeapYear(new Date().getFullYear()) ? 366 : 365;
  var daysInMonth = new Date(new Date().getFullYear(), month, 0).getDate();
  var interest = remainingLoan * (daysInMonth * interestRate / 100 / daysInYear);
  return Number(interest.toFixed(0));
}
/**
 * Рассчитывает детали платежа, включая погашение основного долга, проценты и оставшийся долг.
 * @param {number} remainingLoan -  Сумма займа (основной долг)
 * @param {number} monthlyPayment - Ежемесячный платёж
 * @param {number} monthlyInterest - Ежемесячный процент
 * @returns платеж
 */
function calculatePaymentDetails(remainingLoan, monthlyPayment, monthlyInterest) {
  var principalPayment, periodPayment, loanLeft;
  if (remainingLoan <= monthlyPayment) {
    principalPayment = remainingLoan;
    periodPayment = principalPayment + monthlyInterest;
    loanLeft = 0;
  } else {
    principalPayment = monthlyPayment - monthlyInterest;
    periodPayment = monthlyPayment;
    loanLeft = remainingLoan - principalPayment;
  }
  return {
    principalPayment: principalPayment,
    monthlyInterest: monthlyInterest,
    periodPayment: periodPayment,
    loanLeft: loanLeft
  };
}
/**
 * Считаем досрочный платеж.
 * @param {number} loanAmount - Остаток кредита
 * @param {number} earlyPayment - Досрочный платеж
 * @param {string} earlyPaymentReduction - Тип досрочного погашения:
 *  'reducePayment' - уменьшает сумму ежемесячного платежа
 *  'reducePeriod' - уменьшает срок кредита
 * @param {number} monthlyPayment - Ежемесячный платеж
 * @param {number} interestRate - Процентная ставка
 * @param {number} loanPeriod - Срок кредита
 * @param {number} monthsLeft - Остаток месяцев до конца кредита
 * @returns - Новый остаток кредита и новый ежемесячный платеж
 */
function handleEarlyPayment(loanAmount, earlyPayment, earlyPaymentReduction, monthlyPayment, interestRate, loanPeriod, monthsLeft) {
  var newLoanLeft = loanAmount - earlyPayment;
  var newMonthlyPayment = monthlyPayment;
  var newEarlyPayment = earlyPayment;
  if (earlyPaymentReduction === 'reducePayment') {
    newMonthlyPayment = calculateMonthlyPayment(newLoanLeft, interestRate, loanPeriod, ++monthsLeft);
  }
  if (newLoanLeft < 0) {
    newEarlyPayment = earlyPayment + newLoanLeft;
  }
  newLoanLeft = Math.max(newLoanLeft, 0);
  return {
    newLoanLeft: newLoanLeft,
    newMonthlyPayment: newMonthlyPayment,
    newEarlyPayment: newEarlyPayment
  };
}
/**
 * Рассчитывает график платежей по ипотеке
 * @param loanAmount Сумма займа (основной долг)
 * @param interestRate Процентная ставка (годовая)
 * @param loanPeriod Срок займа в годах
 * @returns Массив объектов MortgageResultsTableItem
 */
export function calculatePaymentSchedule(loanAmount, interestRate, loanPeriod, earlyPayment, earlyPaymentDate, earlyPaymentReduction, earlyPaymentType) {
  var results = [];
  if (!loanAmount || !interestRate || !loanPeriod) {
    return results;
  }
  var monthlyPayment = Number(calculateMonthlyPayment(loanAmount, interestRate, loanPeriod).toFixed(0));
  var monthlyRate = interestRate / 100 / 12;
  var earlyPaymentMonth = earlyPaymentDate && new Date(earlyPaymentDate).getMonth() + 1;
  var earlyPaymentYear = earlyPaymentDate && new Date(earlyPaymentDate).getFullYear();
  var isEarlyPaymentCalc = Boolean(Number(earlyPayment)) && (earlyPaymentDate !== null && earlyPaymentDate !== void 0 ? earlyPaymentDate : 0) > new Date().getTime() && earlyPaymentReduction && earlyPaymentType;
  var totalMonths = loanPeriod * 12;
  var remainingLoan = loanAmount;
  var currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() + 1);
  var startMonth = currentDate.getMonth();
  var periodPrincipalPayment = 0;
  var periodInterestPayment = 0;
  var periodPayment = 0;
  var loanLeft = remainingLoan;
  var item = {};
  var extraPaymentItem = {};
  for (var month = startMonth;; month++) {
    // Есть досрочный платёж
    if (isEarlyPaymentCalc) {
      var monthlyInterest = calculateMonthlyInterest(remainingLoan, interestRate, currentDate.getMonth());
      var isSingleEarlyPayment = earlyPaymentType === 'single' && currentDate.getMonth() + 1 === earlyPaymentMonth && currentDate.getFullYear() === earlyPaymentYear;
      var isMonthlyEarlyPayment = earlyPaymentType === 'monthly' && currentDate >= new Date(earlyPaymentYear, earlyPaymentMonth - 1, 1);
      var paymentDetails = calculatePaymentDetails(remainingLoan, monthlyPayment, monthlyInterest);
      if ((isSingleEarlyPayment || isMonthlyEarlyPayment) && remainingLoan > monthlyPayment) {
        var monthsLeft = totalMonths - month + 1;
        var _handleEarlyPayment = handleEarlyPayment(paymentDetails.loanLeft, Number(earlyPayment), earlyPaymentReduction, monthlyPayment, interestRate, loanPeriod, monthsLeft),
          newLoanLeft = _handleEarlyPayment.newLoanLeft,
          newMonthlyPayment = _handleEarlyPayment.newMonthlyPayment,
          newEarlyPayment = _handleEarlyPayment.newEarlyPayment;
        extraPaymentItem = {
          period: "\u0414\u041F (".concat(getPeriodLabel(currentDate, 'month'), ")"),
          periodPayment: Number(newEarlyPayment),
          principalPayment: Number(newEarlyPayment),
          interestPayment: 0,
          loanLeft: Number(newLoanLeft.toFixed(0)),
          additionalPaymentId: 1
        };
        remainingLoan = newLoanLeft;
        monthlyPayment = Number(newMonthlyPayment.toFixed(0));
      } else {
        extraPaymentItem = null;
        remainingLoan = paymentDetails.loanLeft;
      }
      // Регулярные платежи за период
      periodPayment += paymentDetails.periodPayment;
      periodPrincipalPayment += paymentDetails.principalPayment;
      periodInterestPayment += paymentDetails.monthlyInterest;
      loanLeft = paymentDetails.loanLeft;
      var period = getPeriodLabel(currentDate, 'month');
      item = {
        period: period,
        periodPayment: Number(periodPayment.toFixed(0)),
        principalPayment: Number(periodPrincipalPayment.toFixed(0)),
        interestPayment: Number(periodInterestPayment.toFixed(0)),
        loanLeft: Number(loanLeft.toFixed(0))
      };
      results.push(item);
      if ((isSingleEarlyPayment || isMonthlyEarlyPayment) && extraPaymentItem) {
        results.push(extraPaymentItem);
      }
      periodPrincipalPayment = 0;
      periodInterestPayment = 0;
      periodPayment = 0;
      loanLeft = remainingLoan;
      item = {};
    } else {
      // Обычная ипотека без досрочных платежей
      var interestPayment = remainingLoan * monthlyRate;
      var principalPayment = monthlyPayment - interestPayment;
      if (remainingLoan <= monthlyPayment) {
        principalPayment = remainingLoan;
      }
      remainingLoan -= principalPayment;
      remainingLoan = Math.max(remainingLoan, 0);
      if (month !== totalMonths) {
        results.push({
          period: getPeriodLabel(currentDate, 'month'),
          periodPayment: Number(monthlyPayment.toFixed(0)),
          principalPayment: Number(principalPayment.toFixed(0)),
          interestPayment: Number(interestPayment.toFixed(0)),
          loanLeft: Number(remainingLoan.toFixed(0))
        });
      }
    }
    remainingLoan = Math.max(0, remainingLoan);
    if (!remainingLoan) {
      return results;
    }
    currentDate.setDate(1);
    currentDate.setMonth(currentDate.getMonth() + 1);
  }
}
/**
 * Рассчитывает график платежей по рассрочке с учётом выбранного периода расчёта.
 * Поддерживает ежемесячные, квартальные и годовые платежи, а также досрочное погашение.
 * Первый платёж сдвигается на месяц после начала периода, а долг полностью погашается к концу срока.
 *
 * @param state - Состояние рассрочки, содержащее параметры расчёта.
 * @param state.installmentPeriod - Период рассрочки с датами начала и окончания.
 * @param state.payment - Сумма регулярного платежа (ежемесячного).
 * @param state.selectedSettlementType - Тип периода расчёта: "month" (месяц), "quarter" (квартал) или "year" (год).
 * @param state.additionalPayments - Массив дополнительных платежей с датами и суммами.
 * @returns Массив объектов {@link InstallmentResultsTableItem}, представляющих график платежей с периодами, суммами и остатками долга.
 */
export function calculateInstallmentSchedule(state) {
  var from = new Date(state.installmentPeriod.from);
  var currentDate = new Date(from);
  currentDate.setDate(1);
  currentDate.setMonth(currentDate.getMonth());
  var to = new Date(state.installmentPeriod.to);
  var totalMonths = (to.getFullYear() - from.getFullYear()) * 12 + (to.getMonth() - from.getMonth()) + Number(currentDate.getMonth()) + 1;
  var remainingLoan = debtStartPeriod(state);
  var paymentIndex = 0;
  var results = [];
  var periodLength = periods[state.selectedSettlementType] || 1;
  var monthlyPayment = Number(state.payment);
  var totalPeriods = Math.ceil(totalMonths / periodLength);
  periodStartYear = 1;
  currentYear = 0;
  for (var monthIndex = currentDate.getMonth(); monthIndex < totalMonths && remainingLoan > 0; monthIndex++) {
    var isPeriodEnd = (monthIndex + 1) % periodLength === 0 || monthIndex === totalMonths - 1;
    if (isPeriodEnd) {
      var periodNumber = Math.floor(monthIndex / periodLength) + 1;
      var periodPayment = monthlyPayment;
      if (periodNumber === totalPeriods || remainingLoan <= monthlyPayment) {
        periodPayment = remainingLoan;
      }
      remainingLoan -= periodPayment;
      remainingLoan = Math.max(remainingLoan, 0);
      var periodLabel = getPeriodLabel(currentDate, state.selectedSettlementType, true);
      results.push({
        period: periodLabel,
        periodPayment: periodPayment,
        loanLeft: remainingLoan
      });
      if (state.additionalPayments.length) {
        var _iterator = _createForOfIteratorHelper(state.additionalPayments),
          _step;
        try {
          var _loop = function _loop() {
              var payment = _step.value;
              var _payment$dateString$s = payment.dateString.split(' '),
                _payment$dateString$s2 = _slicedToArray(_payment$dateString$s, 2),
                month = _payment$dateString$s2[0],
                year = _payment$dateString$s2[1];
              var isPaymentFound = false;
              var isYearEqual = Number(year) === currentDate.getFullYear();
              var isQuarterOrYearPaymentFound = !results.some(function (item) {
                return item.additionalPaymentId === payment.id;
              });
              if (state.selectedSettlementType === 'month') {
                isPaymentFound = isYearEqual && monthNames.indexOf(month) === Number(currentDate.getMonth());
              }
              if (state.selectedSettlementType === 'quarter') {
                isPaymentFound = isYearEqual && Math.floor(currentDate.getMonth() / 3) + 1 === Math.floor(monthNames.indexOf(month) / 3) + 1 && isQuarterOrYearPaymentFound;
              }
              if (state.selectedSettlementType === 'year') {
                isPaymentFound = isYearEqual && isQuarterOrYearPaymentFound;
              }
              if (isPaymentFound) {
                var additionalAmount = remainingLoan - Number(payment.amount) <= 0 ? remainingLoan : Number(payment.amount);
                remainingLoan -= additionalAmount;
                results.push({
                  period: "\u0414\u041F \u2116".concat(++paymentIndex, " (").concat(payment.dateString.replace(' ', ', '), ")"),
                  periodPayment: additionalAmount,
                  loanLeft: Math.max(remainingLoan, 0),
                  additionalPaymentId: payment.id
                });
                remainingLoan = Math.max(remainingLoan, 0);
                if (remainingLoan === 0) {
                  return {
                    v: results
                  };
                }
              }
            },
            _ret;
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            _ret = _loop();
            if (_ret) return _ret.v;
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      }
    }
    currentDate.setMonth(currentDate.getMonth() + 1);
  }
  return results;
}
export function columnData(column, row) {
  return column.name !== 'period' ? splitThousands(Number(row[column.name]).toFixed(2)) : row[column.name];
}