import { StatefulRestClient } from '@@/src/shared/api/Base/StatefulRest.client';
import { usePanelApi, usePiniaStore } from '@@/src/app/plugins';

const CURRENT_PORTRAIT_ENDPOINT = '/api/panel/portrait/';
const PROJECT_PORTRAITS_ENDPOINT = '/api/panel/projects/#project#/portraits/';

export interface Portrait { slug: string; id: number; name: string };

export interface CurrentPortraitResponse {
  slug: string;
};

type GetAllResponse = Portrait[];

export class PortraitsClient extends StatefulRestClient {
  async setCurrent(slug: string) {
    const body = { slug };
    try {
      return await this.sendPostRequest<CurrentPortraitResponse>(CURRENT_PORTRAIT_ENDPOINT, {}, body);
    }
    catch (error) {
      console.error('[PortraitsClient::setCurrent]', (error));

      return null;
    }
  }

  async getCurrent() {
    try {
      return await this.sendGetRequest<CurrentPortraitResponse>(CURRENT_PORTRAIT_ENDPOINT);
    }
    catch (error) {
      console.error('[PortraitsClient::getCurrent]', (error));

      return null;
    }
  }

  async getAllByProjectSlug(slug: string = this.getProjectSlug()) {
    try {
      return await this.sendGetRequest<GetAllResponse>(PROJECT_PORTRAITS_ENDPOINT, { project: slug });
    }
    catch (error) {
      console.error('[PortraitsClient::getAllByProjectSlug]', (error));

      return null;
    }
  }
}

export const usePortraitsClient = () => new PortraitsClient(
  usePanelApi(),
  usePiniaStore()!,
);
