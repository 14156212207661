/**
 * Скопировано из `@nuxt/composition-api`
 * @see https://github.com/nuxt-community/composition-api/blob/main/src/runtime/composables/utils.ts
 */
import { getCurrentInstance as getVueCurrentInstance } from 'vue';

export const getCurrentInstance = () => {
  const vm = getVueCurrentInstance();

  if (!vm) {
    return;
  }

  return vm.proxy;
};
