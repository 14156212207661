import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.number.is-nan.js";
import "core-js/modules/es.object.entries.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { DEFAULT_INSTALLMENT_STATE, DEFAULT_MORTGAGE_STATE, createInstallmentState, createMortgageState, mappingParams } from '@@/src/components/pages/mortgage';
export function useMortgagePrintData() {
  var programType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'mortgage';
  var route = useRoute();
  var paramMapping = mappingParams(programType);
  // Универсальная функция парсинга параметра
  function parseQueryParam(param, defaultValue) {
    if (param === null || param === undefined) {
      return defaultValue;
    }
    if (typeof defaultValue === 'number') {
      var parsed = Number(param);
      return Number.isNaN(parsed) || parsed < 0 ? defaultValue : parsed;
    }
    if (param === 'true' || param === 'false') {
      return param === 'true';
    }
    return param;
  }
  // Сбор данных из query
  var queryParams = Object.entries(paramMapping).reduce(function (acc, _ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      queryKey = _ref2[0],
      config = _ref2[1];
    var paramValue = parseQueryParam(route.query[queryKey], config.default);
    acc[config.key] = paramValue === 'true' ? true : paramValue;
    return acc;
  }, {});
  var mortgageState = _objectSpread({}, DEFAULT_MORTGAGE_STATE);
  var installmentState = _objectSpread({}, DEFAULT_INSTALLMENT_STATE);
  if (programType === 'mortgage') {
    // Формирование структуры MortgageState
    mortgageState = createMortgageState(queryParams);
  } else {
    // Формирование структуры InstallmentState
    // debugger;
    installmentState = createInstallmentState(queryParams, route);
  }
  // LoanAmount и MonthlyPayment отдельно
  var loanAmount = Number(queryParams.loanAmount);
  var monthlyPayment = queryParams.monthlyPayment;
  return {
    mortgageState: mortgageState,
    loanAmount: loanAmount,
    monthlyPayment: monthlyPayment,
    installmentState: installmentState
  };
}