var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "mortgage-results-header"
  }, [_c('div', {
    staticClass: "mortgage-results-header__items"
  }, _vm._l(_setup.headerResultItems, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "mortgage-results-header__item"
    }, [_c('span', {
      staticClass: "mortgage-results-header__item-title"
    }, [_vm._v("\n        " + _vm._s(item.label) + "\n      ")]), _vm._v(" "), _c('span', {
      staticClass: "mortgage-results-header__item-value"
    }, [_vm._v("\n        " + _vm._s(item.value) + "\n        "), item.suffix ? _c('span', [_vm._v(_vm._s(item.suffix))]) : _vm._e()])]);
  }), 0), _vm._v(" "), _c('SButton', {
    staticClass: "mortgage-results-header__button",
    on: {
      "click": _setup.onPrintButtonClick
    },
    scopedSlots: _vm._u([{
      key: "after",
      fn: function fn() {
        return [_c('SIcon', {
          attrs: {
            "icon": "print"
          }
        })];
      },
      proxy: true
    }])
  }, [_vm._v("\n    Распечатать\n    ")])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };