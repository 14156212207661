import { RestClient } from '@@/src/shared/api/Base/Rest.client';
import type { MortgageProgram } from './types';

export class MortgageProgramClient extends RestClient {
  async getPrograms(city: string): Promise<MortgageProgram[]> {
    try {
      const response = await this.sendGetRequest('/mortgages/list', {}, {
        city,
      });

      if (!response || !Array.isArray(response)) {
        return [] as MortgageProgram[];
      }

      return response;
    }
    catch (error: unknown) {
      console.error(error);
      throw error;
    }
  }
}
