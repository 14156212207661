export enum PipelineType {
  Offline = 'offline',
  Online = 'online',
}

export interface Meeting {
  id: string;
  client: Client;
  manager: Manager;
  pipeline: Pipeline;
  state: State;
  area: string;
  floor: string;
  details: Details;
  is_online: boolean;
  datetime_start_fact: string;
  is_loyalty: string;
  loyalty_discount: string;
  last_presentation_id: string;
  payment_types: string;
  booking_types: string;
  active: boolean;
  datetime_start: string;
  datetime_end: string;
  agent_company: string;
  agent_fio: string;
  agent_phone: string;
  comment: string;
  purchase_purpose: number;
  ad_question: string;
  stage_solution_question: string;
  agreed: string;
  adult_count: number;
  child_count: number;
  child_len: number[];
  money: number;
  form_pay: number;
  rooms: null | number[];
  another_type: null | number[];
  id_crm: string;
  next_meeting_datetime: string;
  next_meeting_type: number;
  mortgage_approved: boolean;
  date_reserved: string;
  date_reserved_end: string;
  agency_deal: boolean;
  started: boolean;
  is_amo_finished: boolean;
  was_start: boolean;
  was_finish: boolean;
  updated_at: string;
  is_booked_in_pm: boolean;
  is_offer_dsl: boolean;
  is_offer_anketa: boolean;
  is_offer_favorite: boolean;
  is_offer_lk: boolean;
  cancel_reason: string;
  project: number;
  city: number;
  booked_property: number;
  favorite_property: number[];
  favorite_for_amo: number[];
}

export interface Client {
  id: string;
  id_crm: string;
  email: string;
  phone: string;
  patronymic: string;
  name: string;
  last_name: string;
  is_test: boolean;
  loyalty_discount: string;
}

export interface Manager {
  id: number;
  user: User;
  roles: Role[];
  groups: Group[];
  login: string;
  id_crm: string;
  phone: string;
  photo: string;
  project: number;
  portrait: number;
}

export interface User {
  id: number;
  is_superuser: boolean;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
}

export interface Role {
  id: number;
  title: string;
  description: string;
  group: number;
}

export interface Group {
  id: number;
  name: string;
}

export interface Pipeline {
  id: number;
  type: PipelineType;
  name: string;
  city: number;
}

export interface State {
  id: number;
  name: string;
  is_end_type: boolean;
  pipeline: number;
}

export interface Details {
  total_mortgage_amount: number;
  initial_payment: number;
  has_other_approvals: boolean;
  plan_of_interest: string;
  purchase_term: string;
  selected_items: string;
  client_situation_and_arrangements: string;
  mortgage_program: number;
}
