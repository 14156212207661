import { AboutClient } from '@@/src/shared/api/About/About.client';
import { usePanelApi } from '@@/src/app/plugins/api/panel';
import { usePiniaStore } from '@@/src/app/plugins';
import type { StateTree } from 'pinia';

export class AboutService {
  readonly aboutClient?: AboutClient;
  readonly #piniaStore?: Record<string, StateTree>;

  constructor() {
    const panelApi = usePanelApi();
    this.#piniaStore = usePiniaStore();

    this.aboutClient = new AboutClient(panelApi, this.#piniaStore);
  }
}

// region Composable
// todo: найти более оптимальное решение для синглтона
let aboutService: AboutService | undefined;
export const useAboutService: () => AboutService = () => {
  if (!aboutService) {
    aboutService = new AboutService();
  }

  return aboutService;
};
// endregion
