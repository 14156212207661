import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { useErrorLoggerService } from '@@/src/shared/lib/services/error-logger';
var nuxtApp;
export default defineNuxtPlugin(function (_nuxtApp) {
  nuxtApp = _nuxtApp;
});
export var usePiniaStore = function usePiniaStore() {
  var _nuxtApp2, _nuxtApp3;
  if (!((_nuxtApp2 = nuxtApp) !== null && _nuxtApp2 !== void 0 && _nuxtApp2.$pinia)) {
    throw new Error('PiniaStore instance is not defined :(');
  }
  return (_nuxtApp3 = nuxtApp) === null || _nuxtApp3 === void 0 ? void 0 : _nuxtApp3.$pinia.state.value;
};
export var useRouteRef = function useRouteRef() {
  return computed(function () {
    var _nuxtApp4, _nuxtApp5;
    if (!((_nuxtApp4 = nuxtApp) !== null && _nuxtApp4 !== void 0 && _nuxtApp4.route)) {
      throw new Error('Route instance is not defined :(');
    }
    return (_nuxtApp5 = nuxtApp) === null || _nuxtApp5 === void 0 ? void 0 : _nuxtApp5.route;
  });
};
export var useRoute = function useRoute() {
  var _nuxtApp6, _nuxtApp7;
  if (!((_nuxtApp6 = nuxtApp) !== null && _nuxtApp6 !== void 0 && _nuxtApp6.route)) {
    throw new Error('Route instance is not defined :(');
  }
  return (_nuxtApp7 = nuxtApp) === null || _nuxtApp7 === void 0 ? void 0 : _nuxtApp7.route;
};
export var useErrorLogger = function useErrorLogger() {
  var _nuxtApp8, _nuxtApp9;
  if (!((_nuxtApp8 = nuxtApp) !== null && _nuxtApp8 !== void 0 && _nuxtApp8.$sentry)) {
    throw new Error('Sentry instance is not defined :(');
  }
  return useErrorLoggerService((_nuxtApp9 = nuxtApp) === null || _nuxtApp9 === void 0 ? void 0 : _nuxtApp9.$sentry);
};
export var useAuth = function useAuth() {
  var _nuxtApp10;
  if (!((_nuxtApp10 = nuxtApp) !== null && _nuxtApp10 !== void 0 && _nuxtApp10.$auth)) {
    throw new Error('Auth instance is not defined :(');
  }
  return nuxtApp.$auth;
};
export var useCookies = function useCookies() {
  var _nuxtApp11;
  if (!((_nuxtApp11 = nuxtApp) !== null && _nuxtApp11 !== void 0 && _nuxtApp11.$cookies)) {
    throw new Error('Cookie instance is not defined :(');
  }
  return nuxtApp.$cookies;
};