import type { Pinia } from 'pinia';
import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { usePortalApi } from '@@/src/app/plugins';
import {
  setGlobalPinia,
  useRealtyApp,
} from '@strana-artw/realty';

declare module '@nuxt/types' {
  interface Context {
    $pinia: Pinia;
  }

  interface NuxtAppOptions {
    $pinia: Pinia;
  }
}

// требуется для корректной работы chessboard
export default defineNuxtPlugin(({ $pinia, app }) => {
  setGlobalPinia($pinia);
  useRealtyApp();
  $pinia.use(({ store }: { store: any }) => ({
    apiInstance: usePortalApi(),
    store: {
      ...store,
      $router: app.$router,
      $auth: app.$auth,
      $coockies: app.$cookies,
    },
  }));
});
