import { BookingClient } from '@@/src/shared/api/Booking/Booking.client';
import { usePanelApi } from '@@/src/app/plugins/api/panel';
import { useModalsStore } from '@@/src/shared/@pinia/use-modals-store';
import { usePiniaStore } from '@@/src/app/plugins';
import { useFlatsStore, useMeetingStore } from '@@/src/shared/@pinia';

export interface IFastBooking {
  property_id: number;
  meeting_id: string;
  payment_type: string;
  booking_type: string;
}

class BookingService {
  private readonly bookingClient: BookingClient;
  constructor() {
    const panelApi = usePanelApi();
    const piniaStore = usePiniaStore();

    if (!panelApi) {
      throw new Error('[BookingService] ошибка инициализации');
    }

    this.bookingClient = new BookingClient(panelApi, piniaStore);
  }

  async handleLotBooking(lotId: number) {
    try {
      await this.bookingClient.sendLotBookingRequest(lotId);
      await this.saveBookedLot(lotId);
      await this.updateCurrentMeetingBookedLotId(lotId);

      this.showContextModal();
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.log('Booking error', error);
      this.showContextModal(error);
    }
  }

  async handleFastBooking(payload: IFastBooking) {
    try {
      await this.bookingClient.sendFastBookingRequest(payload);
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.log('Fast Booking error', error);
      throw error;
    }
  }

  private updateCurrentMeetingBookedLotId(id: number) {
    return useMeetingStore().setMeetingBookedLotId(id);
  }

  private saveBookedLot(lotId: number) {
    return useFlatsStore().saveBookedLot(lotId);
  }

  private showContextModal(error: unknown = null) {
    const modalsStore = useModalsStore();

    if (!error) {
      modalsStore.showStatusModal({
        title: 'Бронирование прошло успешно',
        content: 'Окно закроется автоматически',
      });
      return;
    }

    const failTitle = 'Ошибка при бронировании';

    modalsStore.showBugReportModal({
      modal: {
        title: failTitle,
      },
      bugReport: {
        error,
        text: failTitle,
      },
    });
  }
}

const useBookingService = (): BookingService => new BookingService();

export { BookingService, useBookingService };
