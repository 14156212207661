var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "mortgage-results-empty"
  }, [_c('div', {
    staticClass: "mortgage-results-empty__body"
  }, [_c('div', {
    staticClass: "mortgage-results-empty__headline headline-common-2"
  }, [_vm._v("\n      Введите параметры для расчета\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "mortgage-results-empty__text text-common-m"
  }, [_vm._v("\n      " + _vm._s(_setup.placeholderText) + "\n    ")])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };