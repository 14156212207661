var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "mortgage-print"
  }, [_c('header', {
    staticClass: "mortgage-print__header"
  }, [_c('a', {
    staticClass: "mortgage-print__logo",
    attrs: {
      "href": _setup.MAIN_SITE_URL,
      "target": "_blank"
    }
  }, [_c('SLogo')], 1), _vm._v(" "), _c('div', {
    staticClass: "mortgage-print__line"
  }), _vm._v(" "), _c('SButton', {
    staticClass: "mortgage-print__button",
    attrs: {
      "size": "small",
      "theme": "secondary",
      "equal": ""
    },
    on: {
      "click": _setup.onPrintButtonClick
    }
  }, [_c('SIcon', {
    attrs: {
      "icon": "print"
    }
  })], 1), _vm._v(" "), _c('a', {
    staticClass: "mortgage-print__website",
    attrs: {
      "href": _setup.MAIN_SITE_URL,
      "target": "_blank"
    }
  }, [_vm._v("\n      strana.com\n    ")]), _vm._v(" "), _c('a', {
    staticClass: "mortgage-print__website",
    attrs: {
      "href": "tel:+78007076265"
    }
  }, [_vm._v("\n      8 800 707-62-65\n    ")])], 1), _vm._v(" "), _c('section', {
    staticClass: "mortgage-print__details"
  }, [_c('h1', {
    staticClass: "mortgage-print__title"
  }, [_vm._v("\n      " + _vm._s(_setup.headerText) + "\n    ")]), _vm._v(" "), !_vm.isInstallment ? _c('div', {
    staticClass: "mortgage-print__results"
  }, _vm._l(_setup.headerResultItems, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "mortgage-print__result-item"
    }, [_c('span', {
      staticClass: "mortgage-print__result-label text-common-s"
    }, [_vm._v("\n          " + _vm._s(item.label) + "\n        ")]), _vm._v(" "), _c('span', {
      staticClass: "mortgage-print__result-value text-common-m-medium"
    }, [_vm._v("\n          " + _vm._s(item.value) + "\n          "), item.suffix ? _c('span', [_vm._v("\n            " + _vm._s(item.suffix) + "\n          ")]) : _vm._e()])]);
  }), 0) : _vm._e()])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };