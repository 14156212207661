import type { Auth, RefreshSchemeOptions } from '@nuxtjs/auth-next/dist/runtime';
import { RefreshController, RefreshScheme, RequestHandler } from '@nuxtjs/auth-next/dist/runtime';
import type { HTTPRequest, SchemePartialOptions } from '@nuxtjs/auth-next';
import { usePanelApi } from '@@/src/app/plugins';
import { useProjectsService } from '@@/src/shared/lib/services/projects/Projects.service';
import type { UserData } from '@@/src/app/config/common/auth/types';
import { resetSessionState } from '@@/src/shared/lib/helpers/reset-session';
import { useAuthStore, useMeetingStore } from '@@/src/shared/@pinia';

export default class DefaultScheme extends RefreshScheme {
  constructor($auth: Auth, options: SchemePartialOptions<RefreshSchemeOptions>) {
    super($auth, options);

    const apiInstance = usePanelApi();
    // дополнительно перебиваем request handler внутри, потому что ранее в конструкторе super в него напрямую передавался инстанс аксиоса
    $auth.ctx = {
      ...$auth.ctx,
      app: {
        ...$auth.ctx.app,
        $axios: apiInstance,
      },
      $axios: apiInstance,
    };
    // переопределяем контекст накста для наших классов, чтобы подменить инстанс аксиоса и реинициализируем весь конструктор род. класса
    this.requestHandler = new RequestHandler(this, apiInstance);
    this.refreshController = new RefreshController(this);
  }

  async login(endpoint: HTTPRequest) {
    const response = await super.login(endpoint);
    await useProjectsService().getAllProjects();
    await useProjectsService().getUserActiveProject();
    localStorage.removeItem('flats-repair-designer');
    return response;
  }

  async logout(endpoint: HTTPRequest) {
    // TODO: возможно перенести в другое место/вынести в отдельную функцию
    resetSessionState();
    useMeetingStore().finishMeeting();
    return super.logout(endpoint);
  }

  async fetchUser(endpoint?: HTTPRequest) {
    const response = await super.fetchUser(endpoint);

    if (!response) {
      return response;
    }

    const userData = response.data as UserData;

    // TODO: подумать как ещё можно хранить скоупы, возможно не лучший вариант
    let scopes: string[] = [];
    if (userData && 'groups' in userData && userData.groups.length) {
      scopes = userData.groups.map(({ name }) => name);
    }

    response.data.scopes = scopes;

    useAuthStore().user = response.data;

    return response;
  }
}
