import { useAboutPageStore,
  useFavoritesStore,
  useFlatsStore,
  useSurveyStore } from '@@/src/shared/@pinia';

async function resetSessionState() {
  useSurveyStore().clearSurveyMeeting();
  await useFavoritesStore().clearFavorites();
  useAboutPageStore()?.reset();
  useFlatsStore().reset();
  localStorage.removeItem('flats-repair-designer');
}

export { resetSessionState };
