import type { AxiosResponse } from 'axios';
import { StatefulRestClient } from '@@/src/shared/api/Base/StatefulRest.client';
import type { IFastBooking } from './Booking.service';

class BookingClient extends StatefulRestClient {
  LOT_BOOKING_ENDPOINT = '/api/panel/flats/#id#/booking/';
  FAST_BOOKING = '/api/panel/fast_booking/';

  async sendLotBookingRequest(id: number): Promise<AxiosResponse> {
    return this.sendPostRequest(
      this.LOT_BOOKING_ENDPOINT,
      { id: String(id) },
      { meeting: this.getMeetingId() },
    );
  }

  async sendFastBookingRequest(payload: IFastBooking): Promise<AxiosResponse> {
    return this.sendPostRequest(
      this.FAST_BOOKING,
      {},
      { ...payload },
    );
  }
}

export { BookingClient };
