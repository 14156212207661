import type { State } from '@@/src/shared/@pinia';
import { useRouteStore } from '@@/src/shared/@pinia';
import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import type { Route } from 'vue-router';

export default defineNuxtPlugin(({ app }) => {
  function cloneRoute(to: Route, from?: Route): State {
    const clone: State = {
      name: to.name,
      path: to.path,
      hash: to.hash,
      query: to.query,
      params: to.params,
      fullPath: to.fullPath,
      meta: to.meta,
    };

    if (from) {
      clone.from = cloneRoute(from);
    }

    return Object.freeze(clone);
  }

  app.router?.afterEach((to, from) => {
    const clone = cloneRoute(to, from);
    useRouteStore().route = clone;
  });
});
