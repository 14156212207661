import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('table', {
    staticClass: "mortgage-results-table"
  }, [_c('thead', [_c('tr', {
    staticClass: "mortgage-results-table__row mortgage-results-table__row--header"
  }, _vm._l(_setup.columns, function (column) {
    return _c('th', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !(!_vm.mortgageState.isColumnsHidden && _setup.nameOfHiddenColumns.includes(column.name)),
        expression: "!(!mortgageState.isColumnsHidden && nameOfHiddenColumns.includes(column.name))"
      }],
      key: column.name
    }, [_vm._v("\n        " + _vm._s(column.title) + "\n      ")]);
  }), 0)]), _vm._v(" "), _c('tbody', [_vm._l(_setup.visibleItems, function (row, index) {
    return [_c('tr', {
      key: index,
      staticClass: "mortgage-results-table__row",
      class: {
        'mortgage-results-table__row--payment': 'additionalPaymentId' in row && row.additionalPaymentId
      }
    }, _vm._l(_setup.columns, function (column) {
      return _c('td', {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: !(!_vm.mortgageState.isColumnsHidden && _setup.nameOfHiddenColumns.includes(column.name)),
          expression: "!(!mortgageState.isColumnsHidden && nameOfHiddenColumns.includes(column.name))"
        }],
        key: column.name,
        staticClass: "mortgage-results-table__cell",
        class: column.name === 'loanLeft' && row[column.name] === 0 ? 'mortgage-results-table__cell--red' : ''
      }, [_vm._v("\n          " + _vm._s(_setup.columnData(column, row)) + "\n          " + _vm._s(column.name !== 'period' ? _setup.CURRENCY : '') + "\n        ")]);
    }), 0)];
  }), _vm._v(" "), _setup.visibleRows < _setup.items.length ? _c('tr', {
    staticClass: "mortgage-results-table__row mortgage-results-table__row--show-more"
  }, [_c('td', {
    attrs: {
      "colspan": _setup.columns.length,
      "role": "button",
      "tabindex": "0",
      "aria-expanded": _setup.visibleRows >= _setup.items.length
    },
    on: {
      "click": _setup.loadMoreRows,
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _setup.loadMoreRows.apply(null, arguments);
      }
    }
  }, [_vm._v("\n        " + _vm._s(_setup.showMoreText) + "\n      ")])]) : _vm._e(), _vm._v(" "), _vm.mortgageState.isColumnsHidden ? _c('tr', {
    staticClass: "mortgage-results-table__row mortgage-results-table__row--summary"
  }, [_c('td', [_vm._v("\n        Итого\n      ")]), _vm._v(" "), _vm.programType === 'mortgage' ? [_c('td'), _vm._v(" "), _c('td', [_vm._v("\n          " + _vm._s(_setup.splitThousands(_setup.totalPrincipalPayment)) + " " + _vm._s(_setup.CURRENCY) + "\n        ")]), _vm._v(" "), _vm.programType === 'mortgage' ? _c('td', [_vm._v("\n          " + _vm._s(_setup.splitThousands(_setup.totalInterestPayment)) + " " + _vm._s(_setup.CURRENCY) + "\n        ")]) : _vm._e(), _vm._v(" "), _c('td')] : [_c('td', [_vm._v(_vm._s(_setup.splitThousands(_setup.totalInstallment)) + " " + _vm._s(_setup.CURRENCY))]), _vm._v(" "), _c('td', [_vm._v("0 " + _vm._s(_setup.CURRENCY))])]], 2) : _vm._e()], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };