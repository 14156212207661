export var useRouteStore = definePiniaStore('route-store', function () {
  var route = ref({
    name: null,
    path: '/',
    hash: '',
    query: {},
    params: {},
    fullPath: '/',
    meta: {}
  });
  return {
    route: route
  };
});