import type { ProgramType } from './types';

export function mappingParams(programType: ProgramType = 'mortgage') {
  let paramMapping = {} as Record<string, { key: string; default: number | string | boolean }>;

  if (programType === 'mortgage') {
    paramMapping = {
      realEstatePrice: { key: 'realEstatePrice', default: 0 },
      initialPayment: { key: 'initialPayment', default: 0 },
      initialPaymentPercent: { key: 'initialPaymentPercent', default: 0 },
      loanPeriod: { key: 'loanPeriod', default: 0 },
      interestRate: { key: 'interestRate', default: 0 },
      selectedSettlementType: { key: 'selectedSettlementType', default: 'month' },
      loanAmount: { key: 'loanAmount', default: 0 },
      monthlyPayment: { key: 'monthlyPayment', default: 0 },
      earlyPayment: { key: 'earlyPayment', default: 0 },
      earlyPaymentDate: { key: 'earlyPaymentDate', default: '' },
      earlyPaymentReduction: { key: 'earlyPaymentReduction', default: '' },
      earlyPaymentType: { key: 'earlyPaymentType', default: '' },
      isColumnsHidden: { key: 'isColumnsHidden', default: false },

    };
  }
  else {
    paramMapping = {
      from: { key: 'from', default: 0 },
      to: { key: 'to', default: 0 },
      selectedSettlementType: { key: 'selectedSettlementType', default: 'month' },
      realEstatePrice: { key: 'realEstatePrice', default: 0 },
      discount: { key: 'discount', default: 0 },
      bookingPrice: { key: 'bookingPrice', default: 0 },
      kitchen: { key: 'kitchen', default: 0 },
      furniture: { key: 'furniture', default: 0 },
      initialPayment: { key: 'initialPayment', default: 0 },
      smartHome: { key: 'smartHome', default: 0 },
      isJustRestRoom: { key: 'isJustRestRoom', default: false },
      other: { key: 'other', default: 0 },
      square: { key: 'square', default: 0 },
      discountPercent: { key: 'discountPercent', default: 0 },
      repairCostRub: { key: 'repairCostRub', default: 0 },
      repairCost: { key: 'repairCost', default: 0 },
      payment: { key: 'payment', default: 0 },
    };
  }

  return paramMapping;
}

export function createMortgageState(queryParams: Record<string, any>) {
  return ({
    realEstatePrice: queryParams.realEstatePrice,
    initialPayment: queryParams.initialPayment,
    initialPaymentPercent: queryParams.initialPaymentPercent,
    loanPeriod: queryParams.loanPeriod,
    interestRate: queryParams.interestRate,
    selectedSettlementType: queryParams.selectedSettlementType,
    selectedProgram: null,
    isCustomInterestRate: false,
    earlyPayment: queryParams.earlyPayment,
    earlyPaymentDate: Number(queryParams.earlyPaymentDate),
    earlyPaymentReduction: queryParams.earlyPaymentReduction,
    earlyPaymentType: queryParams.earlyPaymentType,
    isColumnsHidden: queryParams.isColumnsHidden,
  });
}

export function createInstallmentState(queryParams: Record<string, any>, route: any) {
  let additionalPayments = null;

  try {
    additionalPayments = route.query.additionalPayments ? JSON.parse(route.query.additionalPayments as string) : null;
  }
  catch (e) {
    console.error(e);
  }

  return ({
    installmentPeriod: { from: queryParams.from, to: queryParams.to },
    selectedSettlementType: queryParams.selectedSettlementType,
    realEstatePrice: queryParams.realEstatePrice,
    discount: queryParams.discount,
    bookingPrice: queryParams.bookingPrice,
    kitchen: queryParams.kitchen,
    furniture: queryParams.furniture,
    initialPayment: queryParams.initialPayment,
    smartHome: queryParams.smartHome,
    isJustRestRoom: queryParams.isJustRestRoom,
    other: queryParams.other,
    square: queryParams.square,
    discountPercent: queryParams.discountPercent,
    repairCostRub: queryParams.repairCostRub,
    repairCost: queryParams.repairCost,
    payment: queryParams.payment,
    additionalPayments,
  });
}
