import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('table', {
    staticClass: "mortgage-results-table"
  }, [_c('thead', [_c('tr', {
    staticClass: "mortgage-results-table__row mortgage-results-table__row--header"
  }, _vm._l(_setup.printInstallmentColumns, function (column) {
    return _c('th', {
      key: column.name
    }, [_vm._v("\n        " + _vm._s(column.title) + "\n      ")]);
  }), 0)]), _vm._v(" "), _c('tbody', [_vm._l(_setup.hiddenAdditionalPayments, function (row, index) {
    return [_c('tr', {
      key: index,
      staticClass: "mortgage-results-table__row mortgage-results-table__row--payment"
    }, _vm._l(_setup.columns, function (column) {
      return _c('td', {
        key: column.name,
        staticClass: "mortgage-results-table__cell"
      }, [_vm._v("\n          " + _vm._s(_setup.columnData(column, row)) + "\n          " + _vm._s(column.name !== 'period' ? _setup.CURRENCY : '') + "\n        ")]);
    }), 0)];
  })], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };