import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/esnext.string.at.js";
import { plural } from '@@/src/shared/lib/utils/commonUtils';
import { calculateInstallmentSchedule, calculatePaymentSchedule, debtStartPeriod } from '@@/src/components/pages/mortgage';
var titles = {
  month: 'Месяц, год',
  quarter: 'Квартал, год',
  year: 'Год'
};
var postfixes = {
  month: ['месяц', 'месяца', 'месяцев'],
  quarter: ['квартал', 'квартала', 'кварталов'],
  year: ['год', 'года', 'лет']
};
var postfixesInstallment = {
  payment: ['платеж', 'платежа', 'платежей']
};
export function useMortgageTable(props, isPrintPage) {
  // Константы
  var DEFAULT_VISIBLE_MORTGAGE_ROWS = 5;
  var DEFAULT_VISIBLE_INSTALLMENT_ROWS = 12;
  var LOAD_MORE_MORTGAGE_ROWS_COUNT = 10;
  var LOAD_MORE_INSTALLMENT_ROWS_COUNT = 12;
  var DEFAULT_VISIBLE_PRINT_INSTALLMENT_ROWS = 5;
  // Заголовок для колонки периода
  var periodColumnTitle = computed(function () {
    if (props.programType === 'installment') {
      var _props$installmentSta;
      return (props === null || props === void 0 || (_props$installmentSta = props.installmentState) === null || _props$installmentSta === void 0 ? void 0 : _props$installmentSta.selectedSettlementType) && titles[props.installmentState.selectedSettlementType] || titles.year;
    }
    return titles.month;
  });
  // Заголовок для колонки платежа
  var periodPaymentColumnTitle = computed(function () {
    var titles = {
      month: 'Платёж в месяц',
      quarter: 'Платёж в квартал',
      year: 'Платёж в год'
    };
    if (props.programType === 'installment') {
      return titles[props.installmentState.selectedSettlementType];
    }
    return titles.month;
  });
  // Колонки таблицы
  var mortgageColumns = computed(function () {
    return [{
      title: periodColumnTitle.value,
      name: 'period'
    }, {
      title: periodPaymentColumnTitle.value,
      name: 'periodPayment'
    }, {
      title: 'Основной долг',
      name: 'principalPayment'
    }, {
      title: 'Проценты',
      name: 'interestPayment'
    }, {
      title: 'Остаток',
      name: 'loanLeft'
    }];
  });
  var installmentColumns = computed(function () {
    return [{
      title: periodColumnTitle.value,
      name: 'period'
    }, {
      title: periodPaymentColumnTitle.value,
      name: 'periodPayment'
    }, {
      title: 'Остаток',
      name: 'loanLeft'
    }];
  });
  var printInstallmentColumns = computed(function () {
    return [{
      title: titles.month,
      name: 'period'
    }, {
      title: 'Платёж в месяц',
      name: 'periodPayment'
    }, {
      title: 'Остаток',
      name: 'loanLeft'
    }];
  });
  // Список платежей
  var mortgageItems = computed(function () {
    return calculatePaymentSchedule(props.loanAmount, props.mortgageState.interestRate, props.mortgageState.loanPeriod, props.mortgageState.earlyPayment, props.mortgageState.earlyPaymentDate, props.mortgageState.earlyPaymentReduction, props.mortgageState.earlyPaymentType);
  });
  var installmentItems = computed(function () {
    return calculateInstallmentSchedule(props.installmentState);
  });
  var items = computed(function () {
    if (props.programType === 'mortgage') {
      return mortgageItems.value;
    }
    return installmentItems.value;
  });
  var columns = computed(function () {
    return props.programType === 'mortgage' ? mortgageColumns.value : installmentColumns.value;
  });
  var visibleRowsDependingOnState = computed(function () {
    if (props.programType === 'mortgage') {
      return DEFAULT_VISIBLE_MORTGAGE_ROWS;
    } else if (isPrintPage) {
      return DEFAULT_VISIBLE_PRINT_INSTALLMENT_ROWS;
    }
    return DEFAULT_VISIBLE_INSTALLMENT_ROWS;
  });
  var loadMoreRowsDependingOnState = computed(function () {
    return props.programType === 'mortgage' ? LOAD_MORE_MORTGAGE_ROWS_COUNT : LOAD_MORE_INSTALLMENT_ROWS_COUNT;
  });
  // Состояние количества видимых строк
  var visibleRows = ref(visibleRowsDependingOnState.value);
  // Видимые элементы
  var visibleItems = computed(function () {
    return items.value.slice(0, visibleRows.value);
  });
  var lastItems = computed(function () {
    if (items.value.length < visibleRows.value) {
      return [];
    }
    return items.value.slice(-visibleRows.value).filter(function (item) {
      return !visibleItems.value.includes(item);
    });
  });
  // Постфиксы для кнопки "Показать еще"
  var showMorePostfixes = computed(function () {
    if (props.programType === 'mortgage') {
      return postfixes.month;
    }
    return postfixesInstallment.payment;
  });
  // Текст кнопки "Показать еще"
  var showMoreText = computed(function () {
    var remaining = Math.max(items.value.length - visibleRows.value, 0);
    var count = Math.min(remaining, loadMoreRowsDependingOnState.value);
    return "\u041F\u043E\u043A\u0430\u0437\u0430\u0442\u044C \u0435\u0449\u0435 ".concat(count, " ").concat(plural(count, showMorePostfixes.value));
  });
  // Итоговые значения для ипотеки
  var totalPrincipalPayment = computed(function () {
    return mortgageItems.value.reduce(function (sum, item) {
      return sum + item.principalPayment;
    }, 0);
  });
  var totalInterestPayment = computed(function () {
    return mortgageItems.value.reduce(function (sum, item) {
      return sum + item.interestPayment;
    }, 0);
  });
  // итоговые значения для рассрочки
  var totalInstallment = computed(function () {
    return debtStartPeriod(props.installmentState);
  });
  var totalHiddenPeriods = computed(function () {
    if (props.programType === 'mortgage') {
      return '•••';
    }
    if (installmentItems.value.length < DEFAULT_VISIBLE_INSTALLMENT_ROWS) {
      return null;
    }
    if (installmentItems.value.length > DEFAULT_VISIBLE_PRINT_INSTALLMENT_ROWS) {
      var _slicedData$find$peri, _slicedData$find, _installmentItems$val, _installmentItems$val2;
      var slicedData = installmentItems.value.slice(DEFAULT_VISIBLE_PRINT_INSTALLMENT_ROWS);
      var first = (_slicedData$find$peri = (_slicedData$find = slicedData.find(function (item) {
        return !item.additionalPaymentId;
      })) === null || _slicedData$find === void 0 || (_slicedData$find = _slicedData$find.period) === null || _slicedData$find === void 0 ? void 0 : _slicedData$find.replace(',', '')) !== null && _slicedData$find$peri !== void 0 ? _slicedData$find$peri : '';
      var last = (_installmentItems$val = (_installmentItems$val2 = installmentItems.value.at(-DEFAULT_VISIBLE_PRINT_INSTALLMENT_ROWS - 1)) === null || _installmentItems$val2 === void 0 || (_installmentItems$val2 = _installmentItems$val2.period) === null || _installmentItems$val2 === void 0 ? void 0 : _installmentItems$val2.replace(',', '')) !== null && _installmentItems$val !== void 0 ? _installmentItems$val : '';
      if (first === last) {
        return first;
      }
      return "".concat(first, " - ").concat(last);
    }
    return '•••';
  });
  var hiddenAdditionalPayments = computed(function () {
    var end = installmentItems.value.length - DEFAULT_VISIBLE_PRINT_INSTALLMENT_ROWS - 1;
    return installmentItems.value.slice(DEFAULT_VISIBLE_PRINT_INSTALLMENT_ROWS, end).filter(function (item) {
      return item.additionalPaymentId;
    });
  });
  // Методы
  var loadMoreRows = function loadMoreRows() {
    visibleRows.value += loadMoreRowsDependingOnState.value;
  };
  return {
    columns: columns,
    items: items,
    visibleRows: visibleRows,
    visibleItems: visibleItems,
    lastItems: lastItems,
    showMoreText: showMoreText,
    totalPrincipalPayment: totalPrincipalPayment,
    totalInterestPayment: totalInterestPayment,
    totalInstallment: totalInstallment,
    totalHiddenPeriods: totalHiddenPeriods,
    hiddenAdditionalPayments: hiddenAdditionalPayments,
    printInstallmentColumns: printInstallmentColumns,
    loadMoreRows: loadMoreRows
  };
}