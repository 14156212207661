import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.number.constructor.js";
import { monthByNumber, priceFormat } from '@@/src/shared/lib/utils/commonUtils';
export function useLotValues(props) {
  var _toRefs = toRefs(props),
    lot = _toRefs.lot;
  var flatId = computed(function () {
    var _lot$value, _lot$value2, _lot$value3;
    var flatId = (_lot$value = lot.value) === null || _lot$value === void 0 ? void 0 : _lot$value.flatId;
    var firstFlatId = (_lot$value2 = lot.value) === null || _lot$value2 === void 0 ? void 0 : _lot$value2.firstFlatId;
    var id = 'id' in lot.value ? (_lot$value3 = lot.value) === null || _lot$value3 === void 0 ? void 0 : _lot$value3.id : '';
    return flatId || firstFlatId || id;
  });
  var project = computed(function () {
    var _useRoute, _lot$value4;
    var routeSlug = (_useRoute = useRoute()) === null || _useRoute === void 0 || (_useRoute = _useRoute.params) === null || _useRoute === void 0 ? void 0 : _useRoute.slug;
    if (routeSlug) {
      return routeSlug;
    }
    var lotProjectSlug = 'project' in lot.value ? (_lot$value4 = lot.value) === null || _lot$value4 === void 0 || (_lot$value4 = _lot$value4.project) === null || _lot$value4 === void 0 ? void 0 : _lot$value4.slug : undefined;
    if (lotProjectSlug) {
      return lotProjectSlug;
    }
    return null;
  });
  var mainPrice = computed(function () {
    var price = lot.value.fullFinalPrice;
    if (price) {
      var _toValue;
      var prefix = (_toValue = toValue(lot)) !== null && _toValue !== void 0 && _toValue.flatsCountMoreThan ? 'от ' : '';
      return "".concat(prefix).concat(priceFormat(Math.round(price)));
    }
    return '';
  });
  var price = computed(function () {
    var priceRaw = lot.value.originalPrice;
    if (priceRaw) {
      return "".concat(priceFormat(Math.round(priceRaw)));
    }
    return '';
  });
  var tags = computed(function () {
    var lotValue = lot.value;
    var tags = [];
    if (!(lotValue !== null && lotValue !== void 0 && lotValue.isParking) && 'hasParking' in lotValue && lotValue.hasParking) {
      tags.push({
        name: 'Паркинг в подарок',
        color: 'primary-common'
      });
    }
    if ('hasActionParking' in lotValue && lotValue.hasActionParking) {
      tags.push({
        name: 'Паркинг по спец.цене',
        color: 'primary-common'
      });
    }
    if ('hasView' in lotValue && lotValue.hasView) {
      tags.push('Видовая');
    }
    if ('hasTerrace' in lotValue && lotValue.hasTerrace) {
      tags.push('С террасой');
    }
    if ('hasHighCeiling' in lotValue && lotValue.hasHighCeiling) {
      tags.push('Высокие потолки');
    }
    if ('isDuplex' in lotValue && lotValue.isDuplex) {
      tags.push('Двухуровневая');
    }
    if ('facing' in lotValue && lotValue.facing) {
      tags.push('С ремонтом');
    }
    if ('hasPanoramicWindows' in lotValue && lotValue.hasPanoramicWindows) {
      tags.push('Панорамные окна');
    }
    if ('installment' in lotValue && lotValue.installment) {
      tags.push('Рассрочка');
    }
    if ('frontage' in lotValue && lotValue.frontage) {
      tags.push('Палисадник');
    }
    if ('preferentialMortgage' in lotValue && lotValue.preferentialMortgage) {
      tags.push('Ипотека 1,3%');
    }
    if ('cornerWindows' in lotValue && lotValue.cornerWindows) {
      tags.push('Угловые окна');
    }
    if ('promoEnd' in lotValue && lotValue.promoEnd) {
      var date = new Date(lotValue.promoEnd);
      var now = Date.now();
      var diff = Math.ceil(Number(date) - now);
      if (diff > 0) {
        tags.push({
          name: "\u0410\u043A\u0446\u0438\u044F \u0434\u043E ".concat(date.getDate(), " ").concat(monthByNumber(date.getMonth(), 'short'), "."),
          color: 'primary-common'
        });
      }
    }
    return tags;
  });
  var lotTitle = computed(function () {
    var _toValue2, _toValue3, _toValue4;
    var isLayout = (_toValue2 = toValue(lot)) === null || _toValue2 === void 0 ? void 0 : _toValue2.isLayout;
    var isEuro = 'euro' in lot.value ? lot.value.euro : false;
    var lotsQuantity = ((_toValue3 = toValue(lot)) === null || _toValue3 === void 0 ? void 0 : _toValue3.lotsQuantity) || 0;
    var area = (_toValue4 = toValue(lot)) === null || _toValue4 === void 0 ? void 0 : _toValue4.area;
    var rooms = ('rooms' in lot.value ? lot.value.rooms : 0) || 0;
    var type = 'realtyType' in lot.value && lot.value.realtyType || 'type' in lot.value && lot.value.type || '';
    var title;
    switch (type) {
      case 'flat':
        title = rooms === 0 ? 'Студия' : isEuro ? "".concat(rooms, "-\u0435\u0432\u0440\u043E") : "".concat(rooms, "-\u043A\u043E\u043C.");
        break;
      case 'parking':
        title = 'Паркинг';
        break;
      case 'pantry':
        title = 'Кладовка';
        break;
      default:
        title = 'Помещение';
    }
    title += "".concat(isLayout && lotsQuantity > 1 ? ' от' : '', " ").concat(area ? area.toLocaleString() : '', "&nbsp;\u043C<sup>2</sup>");
    return title;
  });
  /**
   * Т.к. не для всех моделей присутствует поле layoutDiscountSize
   * Пример: "похожие квартиры"
   */
  var saleValue = computed(function () {
    var _toValue5, _toValue6;
    var layoutDiscountSize = (_toValue5 = toValue(lot)) === null || _toValue5 === void 0 ? void 0 : _toValue5.layoutDiscountSize;
    var maxDiscount = (_toValue6 = toValue(lot)) === null || _toValue6 === void 0 ? void 0 : _toValue6.maxDiscount;
    var value = typeof layoutDiscountSize === 'number' && layoutDiscountSize ? layoutDiscountSize : maxDiscount;
    var prefix = toValue(lot).flatsCountMoreThan ? 'Скидка до ' : '&minus;';
    return value ? prefix + priceFormat(value) : null;
  });
  var originalPrice = computed(function () {
    var _lot$value5;
    var project = 'project' in lot.value && lot.value.project;
    var isSoon = Boolean(project && 'isSoon' in project && project.isSoon);
    var originalPrice = 'originalPrice' in lot.value && lot.value.originalPrice || undefined;
    if (isSoon || !originalPrice || (_lot$value5 = lot.value) !== null && _lot$value5 !== void 0 && _lot$value5.flatsCountMoreThan) {
      return '';
    }
    if (saleValue.value) {
      return priceFormat(originalPrice);
    }
    return '';
  });
  var labelIcon = computed(function () {
    var isParking = Boolean('isParking' in lot.value && lot.value.isParking);
    var hasParking = Boolean('hasParking' in lot.value && lot.value.hasParking);
    var hasActionParking = Boolean('hasActionParking' in lot.value && lot.value.hasActionParking);
    var isFacing = Boolean('facing' in lot.value && lot.value.facing);
    var isPreferentialMortgage = Boolean('preferentialMortgage4' in lot.value && lot.value.preferentialMortgage4);
    var isMaternalCapital = Boolean('maternalCapital' in lot.value && lot.value.maternalCapital);
    if (saleValue.value || isPreferentialMortgage || isMaternalCapital) {
      return 'percent';
    }
    if (!isParking && (hasParking || hasActionParking)) {
      return 'car';
    }
    if (isFacing) {
      return 'brush';
    }
    return '';
  });
  var url = computed(function () {
    var _lot$value6;
    var slug = toValue(project);
    var id = 'id' in lot.value && lot.value.id || '';
    var realtyType = (_lot$value6 = lot.value) === null || _lot$value6 === void 0 ? void 0 : _lot$value6.realtyType;
    switch (realtyType) {
      case 'parking':
        return {
          name: 'project-slug-parking-id',
          params: {
            slug: slug,
            id: id
          }
        };
      case 'flat':
      default:
        id = toValue(flatId) || '';
        return {
          name: 'project-slug-flats-id',
          params: {
            slug: slug,
            id: id
          },
          query: {
            from: 'selection'
          }
        };
    }
  });
  return {
    mainPrice: mainPrice,
    price: price,
    tags: tags,
    lotTitle: lotTitle,
    saleValue: saleValue,
    labelIcon: labelIcon,
    originalPrice: originalPrice,
    project: project,
    url: url,
    flatId: flatId
  };
}