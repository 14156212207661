import type { AdditionalPayment, InstallmentState, MortgageState } from '@@/src/components/pages/mortgage';

export const CURRENCY = '₽';

export const PROGRAM_TYPES = [
  { label: 'Ипотека', value: 'mortgage' },
  { label: 'Рассрочка', value: 'installment' },
] as const;

export const SETTLEMENT_TYPES = [
  { label: 'Месяц', value: 'month' },
  { label: 'Квартал', value: 'quarter' },
  { label: 'Год', value: 'year' },
] as const;

export const PAYMENT_TYPES = [
  { label: 'Разовый', value: 'single' },
  { label: 'Ежемесячный', value: 'monthly' },
] as const;

export const REDUCTION_OPTIONS = [
  { label: 'Сокращение срока', value: 'reducePeriod' },
  { label: 'Уменьшение платежа', value: 'reducePayment' },
] as const;

export const DEFAULT_MORTGAGE_STATE: MortgageState = {
  realEstatePrice: 0,
  initialPayment: 0,
  initialPaymentPercent: 0,
  loanPeriod: 0,
  interestRate: 0,
  selectedProgram: null,
  isCustomInterestRate: false,
  earlyPayment: '',
  earlyPaymentType: 'single',
  earlyPaymentDate: null,
  earlyPaymentReduction: 'reducePeriod',
  isColumnsHidden: false,
};

export const DEFAULT_INSTALLMENT_STATE: InstallmentState = {
  realEstatePrice: '0',
  initialPayment: 0,
  initialPaymentPercent: 0,
  installmentPeriod: null,
  square: '0',
  discount: 0,
  discountPercent: 0,
  bookingPrice: '0',
  repairCostRub: '0',
  isJustRestRoom: false,
  repairCost: '0',
  kitchen: '0',
  furniture: '0',
  smartHome: '0',
  other: '0',
  selectedSettlementType: 'month',
  payment: '0',
  additionalPayments: [],
  calculatedEstatePrice: 0,
};

export const DEFAULT_PAYMENTS_STATE: AdditionalPayment = {
  id: 0,
  date: null,
  total: 0,
  dateString: '',
  amount: '',
};
