import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "mortgage__filter-content"
  }, [_setup.mortgagePrograms.length ? _c('SSelect', {
    attrs: {
      "z-index": "1000",
      "placeholder": "Выберите ипотечную программу",
      "label": "Ипотечные программы",
      "theme": "panel-white"
    },
    on: {
      "input": _setup.applyProgram
    },
    model: {
      value: _setup.mortgageState.selectedProgram,
      callback: function callback($$v) {
        _vm.$set(_setup.mortgageState, "selectedProgram", $$v);
      },
      expression: "mortgageState.selectedProgram"
    }
  }, _vm._l(_setup.mortgagePrograms, function (option, index) {
    return _c('SOption', {
      key: "option-".concat(option.name, "-").concat(index, "}"),
      attrs: {
        "value": option,
        "label": option.name
      }
    }, [_vm._v("\n      " + _vm._s(option.name) + "\n    ")]);
  }), 1) : _vm._e(), _vm._v(" "), _setup.mortgageState.selectedProgram ? [_c('SInputRangeNext', {
    attrs: {
      "theme": "panel-white",
      "label": "Стоимость недвижимости, ₽",
      "model-value": _setup.mortgageState.realEstatePrice,
      "min": 0,
      "max": _setup.mortgageSummaryState.maxCreditAmount,
      "split-thousands": ""
    },
    on: {
      "update:modelValue": function updateModelValue($event) {
        _setup.mortgageState.realEstatePrice = $event;
      }
    }
  }), _vm._v(" "), _setup.mortgageState.realEstatePrice ? _c('SInputRangePercentNext', {
    attrs: {
      "theme": "panel-white",
      "label": "Первоначальный взнос, ₽",
      "min": _setup.mortgageSummaryState.minInitialPayment,
      "max": 99,
      "total": _setup.mortgageState.realEstatePrice,
      "percent": _setup.mortgageSummaryState.minInitialPayment,
      "step": 1,
      "split-thousands": ""
    },
    on: {
      "update:currency": function updateCurrency($event) {
        _setup.mortgageState.initialPayment = $event;
      },
      "update:percent": function updatePercent($event) {
        _setup.mortgageState.initialPaymentPercent = $event;
      }
    }
  }) : _vm._e(), _vm._v(" "), _c('SCheckbox', {
    attrs: {
      "label-color": "white",
      "theme": "secondary"
    },
    model: {
      value: _setup.mortgageState.isCustomInterestRate,
      callback: function callback($$v) {
        _vm.$set(_setup.mortgageState, "isCustomInterestRate", $$v);
      },
      expression: "mortgageState.isCustomInterestRate"
    }
  }, [_vm._v("\n      Своя процентная ставка\n      "), _c('STooltip', {
    staticClass: "mortgage__filter-rate-tooltip",
    attrs: {
      "theme": "white",
      "width": "fit-content"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_vm._v("\n          При нажатии процентная ставка по программе "), _c('br'), _vm._v(" не будет учитываться и вы сможете указать свою\n        ")];
      },
      proxy: true
    }], null, false, 749131478)
  }, [_c('SIcon', {
    attrs: {
      "icon": "info",
      "size": "20"
    }
  })], 1)], 1), _vm._v(" "), _setup.mortgageState.isCustomInterestRate ? _c('SInputRangeNext', {
    attrs: {
      "theme": "panel-white",
      "label": "Процентная ставка, %",
      "model-value": _setup.mortgageState.interestRate,
      "min": 0,
      "max": 100,
      "step": 0.1
    },
    on: {
      "update:modelValue": function updateModelValue($event) {
        _setup.mortgageState.interestRate = $event;
      }
    }
  }) : _vm._e(), _vm._v(" "), _c('SInputRangeNext', {
    attrs: {
      "theme": "panel-white",
      "label": "Срок кредита, лет",
      "model-value": _setup.mortgageState.loanPeriod,
      "min": 1,
      "max": _setup.mortgageSummaryState.maxCreditPeriod
    },
    on: {
      "update:modelValue": function updateModelValue($event) {
        _setup.mortgageState.loanPeriod = $event;
      }
    }
  }), _vm._v(" "), _c('button', {
    staticClass: "category-item",
    class: {
      active: _setup.isEarlyPaymentOptionsVisible
    },
    on: {
      "click": function click($event) {
        _setup.isEarlyPaymentOptionsVisible = !_setup.isEarlyPaymentOptionsVisible;
      }
    }
  }, [_vm._v("\n      Досрочный платеж\n      "), _c('SIcon', {
    staticClass: "chevron-icon",
    attrs: {
      "icon": "chevron-down",
      "size": "24"
    }
  })], 1), _vm._v(" "), _c('SExpander', {
    attrs: {
      "is-open": _setup.isEarlyPaymentOptionsVisible
    }
  }, [_c('div', {
    staticClass: "mortgage__filter-content"
  }, [_c('SInputNext', {
    staticClass: "expander-indent",
    attrs: {
      "theme": "panel-white",
      "label": "Сумма, ₽",
      "precision": 0,
      "min": 0,
      "split-thousands": "",
      "error-message": "Платёж не может быть больше остатка долга",
      "error-show": Number(_setup.mortgageState.earlyPayment) > _setup.loanAmount
    },
    on: {
      "update:rawValue": function updateRawValue($event) {
        _setup.mortgageState.earlyPayment = $event;
      }
    },
    model: {
      value: _setup.mortgageState.earlyPayment,
      callback: function callback($$v) {
        _vm.$set(_setup.mortgageState, "earlyPayment", $$v);
      },
      expression: "mortgageState.earlyPayment"
    }
  }), _vm._v(" "), _c('SSelect', {
    attrs: {
      "z-index": "1000",
      "placeholder": "Выберете тип платежа",
      "label": "Тип платежа",
      "theme": "panel-white"
    },
    model: {
      value: _setup.mortgageState.earlyPaymentType,
      callback: function callback($$v) {
        _vm.$set(_setup.mortgageState, "earlyPaymentType", $$v);
      },
      expression: "mortgageState.earlyPaymentType"
    }
  }, _vm._l(_setup.PAYMENT_TYPES, function (option, index) {
    return _c('SOption', {
      key: "option-".concat(option.label, "-").concat(index, "}"),
      attrs: {
        "value": option.value,
        "label": option.label
      }
    }, [_vm._v("\n            " + _vm._s(option.label) + "\n          ")]);
  }), 1), _vm._v(" "), _c('SInputDate', {
    attrs: {
      "input-theme": "panel-white",
      "use-teleport": "",
      "label": "Дата начала платежа",
      "min-date": _setup.nextMonthDateTimestamp,
      "required": "",
      "disabled-functor": _setup.isDateDisabled,
      "error-show": _setup.isShowDateError,
      "error-message": "Дата не может быть раньше начала следующего месяца"
    },
    model: {
      value: _setup.mortgageState.earlyPaymentDate,
      callback: function callback($$v) {
        _vm.$set(_setup.mortgageState, "earlyPaymentDate", $$v);
      },
      expression: "mortgageState.earlyPaymentDate"
    }
  }), _vm._v(" "), _c('SSelect', {
    attrs: {
      "z-index": "1000",
      "placeholder": "Выберете тип изменения",
      "label": "Тип изменения",
      "theme": "panel-white"
    },
    model: {
      value: _setup.mortgageState.earlyPaymentReduction,
      callback: function callback($$v) {
        _vm.$set(_setup.mortgageState, "earlyPaymentReduction", $$v);
      },
      expression: "mortgageState.earlyPaymentReduction"
    }
  }, _vm._l(_setup.REDUCTION_OPTIONS, function (option, index) {
    return _c('SOption', {
      key: "option-".concat(option.label, "-").concat(index, "}"),
      attrs: {
        "value": option.value,
        "label": option.label
      }
    }, [_vm._v("\n            " + _vm._s(option.label) + "\n          ")]);
  }), 1), _vm._v(" "), _c('SCheckbox', {
    attrs: {
      "label-color": "white",
      "theme": "secondary"
    },
    model: {
      value: _setup.mortgageState.isColumnsHidden,
      callback: function callback($$v) {
        _vm.$set(_setup.mortgageState, "isColumnsHidden", $$v);
      },
      expression: "mortgageState.isColumnsHidden"
    }
  }, [_vm._v("\n          Дополнительные столбцы\n          "), _c('STooltip', {
    staticClass: "mortgage__filter-rate-tooltip",
    attrs: {
      "theme": "white",
      "width": "fit-content",
      "text-align": "left"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_vm._v("\n              Будут отображаться столбцы: Основной долг, Проценты и строка Итого\n              "), _c('br'), _vm._v(" Также они будут отображаться при печати.\n            ")];
      },
      proxy: true
    }], null, false, 1161783143)
  }, [_c('SIcon', {
    attrs: {
      "icon": "info",
      "size": "20"
    }
  })], 1)], 1)], 1)])] : _vm._e()], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };