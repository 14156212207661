import { StatefulRestClient } from '@@/src/shared/api/Base/StatefulRest.client';
import { usePanelApi, usePiniaStore } from '@@/src/app/plugins';
import type { ConvertSnakeCaseToCamelCase } from '@@/src/shared/lib/helpers/api/camelize';
import { convertObjectKeys } from '@@/src/shared/lib/helpers/api/camelize';
import { routes } from './routes';
import type {
  InfraCategory,
  InfraObject,
  InfrastructureCategory,
  InfrastructureObject,
  KeyInfraCategory, KeyInfraObject,
} from './types';

class InfraClient extends StatefulRestClient {
  async getCategoriesByProjectsIds(projectIds: string[], portrait: string) {
    try {
      return await this.sendGetRequest<InfraCategory[]>(routes.infraCategories, {}, { project: projectIds, portrait })
        .then(res => res.map(category => convertObjectKeys(category as KeyInfraCategory)));
    }
    catch (error) {
      console.error('[InfraClient::getCategoriesByProjectsIds]', (error));

      return null;
    }
  }

  async getCategoryById(id: number, portrait?: string): Promise<ConvertSnakeCaseToCamelCase<KeyInfraCategory> | InfrastructureCategory | null> {
    const query = portrait ? { portrait } : {};

    try {
      return await this.sendGetRequest<InfraCategory>(routes.infraCategoryById, { id }, query)
        .then(category => convertObjectKeys(category as KeyInfraCategory));
    }
    catch (error) {
      console.error('[InfraClient::getCategoryById]', (error));

      return null;
    }
  }

  async getProjectObjectsBySlug(projectSlug: string, portrait: string): Promise<ConvertSnakeCaseToCamelCase<Record<string, unknown>>[] | null | InfrastructureObject[]> {
    try {
      return await this.sendGetRequest<InfraObject[]>(routes.infraObjects, {}, { project: projectSlug, portrait })
        .then(res => (Array.isArray(res) && res.filter(item => item)) || null)
        .then(res => res?.map(project => convertObjectKeys(project as KeyInfraObject)) || null);
    }
    catch (error) {
      console.error('[InfraClient::getProjectObjectsBySlug]', (error));

      return null;
    }
  }
}

export const useInfraClient = () => new InfraClient(
  usePanelApi(),
  usePiniaStore(),
);
