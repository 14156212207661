var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(_setup.SModal, {
    attrs: {
      "type": "center-popup",
      "theme": "white-blue-center",
      "type-options": {
        title: 'Добавление дополнительных платежей',
        width: '82rem',
        height: '52rem'
      }
    },
    on: {
      "close": _setup.handleCloseModal
    },
    scopedSlots: _vm._u([{
      key: "bottom",
      fn: function fn() {
        return [_c('div', {
          staticClass: "buttons-wrapper"
        }, [_c('SButton', {
          staticClass: "buttons-wrapper__button",
          on: {
            "click": _setup.handleSavePayments
          }
        }, [_vm._v("\n        Сохранить\n      ")]), _vm._v(" "), _c('SButton', {
          staticClass: "buttons-wrapper__button",
          attrs: {
            "theme": "ghost"
          },
          on: {
            "click": _setup.handleResetPayments
          }
        }, [_vm._v("\n        Сбросить\n      ")])], 1)];
      },
      proxy: true
    }])
  }, [_vm._l(_setup.temporaryPayments, function (payment, index) {
    return _c('div', {
      key: payment.id,
      staticClass: "payment-block"
    }, [_c('h6', {
      staticClass: "payment-block__title text-common-m-medium"
    }, [_vm._v("\n      Дополнительный платеж №" + _vm._s(index + 1) + "\n    ")]), _vm._v(" "), _c('SInputDate', {
      attrs: {
        "required": "",
        "label": "Дата",
        "use-teleport": "",
        "disabled-functor": _setup.isDateDisabled,
        "error-message": "Обязательное поле",
        "error-show": _setup.isDateError(payment.date) && _setup.isSaving
      },
      on: {
        "input": function input($event) {
          return _setup.convertDateToText(payment);
        }
      },
      model: {
        value: payment.date,
        callback: function callback($$v) {
          _vm.$set(payment, "date", $$v);
        },
        expression: "payment.date"
      }
    }), _vm._v(" "), _c('SInputNext', {
      attrs: {
        "model-value": payment.amount,
        "label": "Сумма, ₽",
        "split-thousands": "",
        "error-message": "Обязательное поле",
        "error-show": !payment.amount && _setup.isSaving
      },
      on: {
        "update:rawValue": function updateRawValue($event) {
          payment.amount = $event;
        }
      }
    })], 1);
  }), _vm._v(" "), _c('div', {
    staticClass: "add-button-block",
    on: {
      "click": _setup.handleAddPayment
    }
  }, [_c('SButton', {
    attrs: {
      "theme": "secondary",
      "size": "small",
      "equal": ""
    }
  }, [_c('SIcon', {
    attrs: {
      "icon": "plus",
      "size": "24"
    }
  })], 1), _vm._v(" "), _c('span', {
    staticClass: "add-button-block__text text-common-s"
  }, [_vm._v("Добавить платеж")])], 1)], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };