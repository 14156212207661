import type { NuxtRuntimeConfig } from '@nuxt/types/config/runtime';
import { getCurrentInstance } from '@@/src/shared/lib/utils/currentInstance';

export const useConfig = (): NuxtRuntimeConfig => {
  const app = getCurrentInstance();
  if (!app) {
    throw new Error('This must be called within a setup function.');
  }
  if (!app?.$config) {
    throw new Error('`$config` not found in App instance.');
  }

  return app.$config;
};
